import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";

export default function WaterSlidesManufacturesInIndia() {
  const imgData = [
    {
      img1: "Product-Images/Family-rides-at-Thorpe-park-manufactures-in-india.jpg",
      name: "FRP GREEN AND YELLOW FAMILY WATER SLIDE",
      price: "Rs 9,000  / sq ft",
      d1: "Minimum Order Quantity",
      value1: " 1 sq ft",
      d2: "Material",
      value2: "FRP(fibre reenforced plastic)",
      d3: "Number Of Slides",
      value3: "1 Slide",
      d4: "Exit",
      value4: "Pool",
      d5: "Color",
      value5: "Yellow and Green",
      d6: "No of Riders",
      value6: "1",
      d7: "Usage/Application",
      value7: "Water Park , Resort",
      d8: "Size",
      value8: "10*6",
      d9: "I deal In",
      value9: "New Only",
      d10: "Country of Origin",
      value10: "Made In India",
      para: "Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.",
    },
    {
      img1: "Product-Images/Water-park-slides-manufactures-in-pune.jpg",
      name: "FRP AQUA WATER PARK SLIDE",
      price: "Rs 17.5 Lakh  / Set",
      d1: "Minimum Order Quantity",
      value1: "5 Set",
      d2: "Material",
      value2: "FRP(fibre reenforced plastic)",
      d3: "Number Of Slides",
      value3: "8 Slide",
      d4: "Exit",
      value4: "Pool",
      d5: "Color",
      value5: "All Colour",
      d6: "Usage/Application",
      value6: "Water Park, Resort,Super Market,Restorant",
      d7: "Equipment Area",
      value7: "1600*1300*600",
      d8: "I Deal In",
      value8: "New Only",
      d9: "Country of Origin",
      value9: "Made in India",
      d10: "Production Capacity",
      value10: "10000/Set",
      para: "Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.",
    },

    {
      img1: "Product-Images/Water-park-equipment-manufactures-in-pune.jpg",
      name: "Large Scale Water Park",
      price: "Rs 30 Lakh  / Set",
      d1: "Minimum Order Quantity",
      value1: "5 Set",
      d2: "Material",
      value2: "FRP(fibre reenforced plastic)",
      d3: "Usage/Application",
      value3: "Water Park/Amusement",
      d4: "Exit",
      value4: "Pool",
      d5: "Color",
      value5: "All Colour",
      d6: "Design",
      value6: "As Per Requirnment",
      d7: " Age Group",
      value7: "Any Age Group",
      d8: "Is It Customized",
      value8: "Customized",
      d9: "Standard",
      value9: "EN 1069, ASTM",
      para: " Backed by a rich industrial experience, we have come up with a world class assortment of FRP Playground Slides. Features:DurableGood quality Excellent ",
    },

    {
      img1: "Product-Images/Tube-slides-water-park-contractor-in-pune.jpg",
      name: " FRP WATER SLIDE,SPERICAL SLIDE,FRP WAVE SLIDE,TUBE SLIDE",
      price: "Rs 12 Lakh  / Set",
      d1: "Minimum Order Quantity",
      value1: "1 Set",
      d2: "Material",
      value2: "FRP(fibre reenforced plastic)",
      d3: "Actual Dimensions ( L x W x H )",
      value3: "AS PER CUSTOMER REQUIRE",
      d4: "Child Age Group",
      value4: "8 YEARS ABOVE",
      d5: "Country of Origin",
      value5: "Made in India",
      d6: "Delivery Time",
      value6: "30-45 DAYS",
      d7: "Packaging Details",
      value7: "AS PER REQUIRE",
      para: "Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.",
    },

    {
      img1: "Product-Images/Water-slides-manufactures-in-india.jpg",
      name: "FRP MINI WATER PARK",
      price: "Rs 15 Lakh / Piece",
      d1: "Minimum Order Quantity",
      value1: "1 Piece",
      d2: "Material",
      value2: "FRP(fibre reenforced plastic)",
      d3: "Number Of Slides",
      value3: "6 Slides",
      d4: "Exit",
      value4: "Pool",
      d5: "Color",
      value5: "ALL COLOUR AVALABLE",
      d6: "No Of Riders",
      value6: "6",
      d7: "Usage/Application",
      value7: "WATER PARK, RESORT",
      d8: "Equipment Area",
      value8: "AS PER REQUIRENMENT",
      d9: " Finishing",
      value9: "ISO GELCOTE FINISH",
      d10: " Tower Height",
      value10: "25 FT",
      d11: " Country of Origin",
      value11: "Made in India",
      para: " Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours. ",
    },
    {
      img1: "Product-Images/Water-play-system-manufacrtures-in-pune.jpg",
      name: "FRP Close Tunnel Water Slides",
      price: "Rs 30 Lakh  / Piece",
      d1: "Minimum Order Quantity",
      value1: "4 Piece",
      d2: "Material",
      value2: "FRP(fibre reenforced plastic)",
      d3: "Number Of Slides",
      value3: "4 slides",
      d4: "Rider Capacity",
      value4: "10-12",
      d5: "Exit",
      value5: "Pool",
      d6: "Color",
      value6: "All Colour Available",
      d7: "No Of Riders",
      value7: "1",
      d8: "Usage/Application",
      value8: "Water park,Resort",
      para: "Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.",
    },

    {
      img1: "Product-Images/Water-park-equipment-manufactures-in-pune.jpg",
      name: "WATER PARK EQUIPMENT",
      price: "Rs 85 Lakh  / Set",
      d1: "Minimum Order Quantity",
      value1: "1 Set",
      d2: "Material",
      value2: "FRP(fibre reenforced plastic)",
      d3: "Color",
      value3: "ALL COLOUR AVAILABLE",
      d4: "Exit",
      value4: "Pool",
      d5: "Usage/Application",
      value5: "WATER PARK",
      d6: "Design",
      value6: "AS PER CUSTMER REQUARNMENT",
      d7: "Age Group",
      value7: "8 YEARS ABOVE",
      d8: "Size",
      value8: "AS PER REQUARNMENT",
      d9: "Bucket Splashing Water Capacity",
      value9: "yes",
      d10: "Capacity",
      value10: "High",
      d11: "Delivery Time",
      value11: "120 - 160",
      para: "Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.",
    },
  ];
  return (
    <>

<Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
           Contact: +91 8793191191,
          +91 9762431883|Nageshwar Fibre And Fabrication Industries| Fiber Bathroom Door Manufacturers in Pune
          | Fibre School Furniture Manufacturers in Pune | Garbage Truck Manufacturers in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Bathroom Door Manufacturers in Pune
          | Fibre School Furniture Manufacturers in Pune | Garbage Truck Manufacturers in Pune
        "
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>


     <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Water Slides</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Water Slides Manufacturers in India</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        <div className="container product-section">
          <h1>
          Water Slides Manufacturers in India – Nageshwar Fibre And
            Fabrication Industries
          </h1>
          <p>
          Nageshwar Fibre And Fabrication Industries is a renowned name in the field of water slides manufacturing in India. With years of experience and expertise, we design and manufacture a wide range of water slides tailored for water parks, resorts, amusement parks, and recreational facilities. Our slides are engineered for fun, safety, and durability, ensuring an enjoyable experience for everyone.
          </p>
        </div>

        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
              <div className="mysection1">
                <div className="product-container">
                  <motion.div
                    className="image-container"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                  >
                    <img
                      src={e.img1}
                      className="productimg" // Replace with actual product image
                      alt="FRP Elephant Statue"
                    />
                  </motion.div>

                  {/* Product Info Section */}
                  <motion.div
                    className="product-details"
                    initial={{ x: -100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                  >
                    <h1>{e.name}</h1>
                    <p className="price">{e.price}</p>
                    <div className="details-table">
                      <table>
                        <tbody>
                          <tr>
                            <th>{e.d1}</th>
                            <td>{e.value1}</td>
                          </tr>
                          <tr>
                            <th>{e.d2}</th>
                            <td>{e.value2}</td>
                          </tr>
                          <tr>
                            <th>{e.d3}</th>
                            <td>{e.value3}</td>
                          </tr>
                          <tr>
                            <th>{e.d4}</th>
                            <td>{e.value4}</td>
                          </tr>
                          <tr>
                            <th>{e.d5}</th>
                            <td>{e.value5}</td>
                          </tr>
                          <tr>
                            <th>{e.d6}</th>
                            <td>{e.value6}</td>
                          </tr>
                          <tr>
                            <th>{e.d7}</th>
                            <td>{e.value7}</td>
                          </tr>
                          <tr>
                            <th>{e.d8}</th>
                            <td>{e.value8}</td>
                          </tr>
                          <tr>
                            <th>{e.d9}</th>
                            <td>{e.value9}</td>
                          </tr>
                          <tr>
                            <th>{e.d10}</th>
                            <td>{e.value10}</td>
                          </tr>
                          <tr>
                            <th>{e.d11}</th>
                            <td>{e.value11}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </motion.div>
                </div>
                <div className="content-section">
                  <div className="paragraph-section">
                    <p>{e.para}</p>
                    <p>{e.para1}</p>
                  </div>
                  <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                    <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                  </motion.div>
                </div>
              </div>
            </>
          );
        })}
      </div>

       {/*  ================= Contact Details ==================== */}
       <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
    </>
  );
}
