import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";



export default function Blogs(){

    const blogPosts = [
      {
        imgSrc: "products-img/Inflatable-water-slide-for-kids-and-adults-for-sales.jpg",
        category: ["Water Slides"],
        title: "Inflatable water slide for kids and adults for sales",
        description: "Nageswar Fibre & Fabrication Industries is Listed amongst the reckoned manufactures of aqualitative range of products like Garden dustbins,Portable Cabinsamd much more.our whole product Assortment is designed and manafactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiorityin Quality.",
        link: "/Water-Slides-Manufacturers-in-India"
      },
      {
        imgSrc: "products-img/Animal-and-Fiber-statue-mnaufacturing-in-pune.jpg",
        category: ["Animal Statue"],
        title: "Animal and Fibre statue manufacturing in pune for Sales",
        description: "Nageshwar Fibre and Fabrication Industries in Pune is a prominent name in the manufacturing of animal and fiber statues. Renowned for their artistic craftsmanship and attention to detail, Nageshwar Fibre specializes in creating high-quality statues that are both durable and visually stunning. ",
        link: "/Animal-and-fiber-statue-manufacturers-in-pune"
      },
      {
        imgSrc: "products-img/FRP-Playground-Slides-Manufactures-in-pune.jpg",
        category: ["FRP Playground Slides Manufactures"],
        title: "FRP Playground Slides Manufactures in pune for sales",
        description: "When it comes to creating fun, safe, and durable playground equipment, Nageshwar Fibre And Fabrication Industries stands as a leader among the playground slide manufacturers in Pune.",
        link: "/Playground-Slide-Manufacturers-in-Pune"
      },
      {
        imgSrc: "products-img/Umbrella-Manufactures-in-pune.jpg",
        category: ["Umbrella Manufactures In Pune "],
        title: "Umbrella Manufactures In Pune for Sales",
        description: "Nageshwar Fibre and Fabrication Industries, based in Pune, is a leading manufacturer of high-quality umbrellas designed for various outdoor purposes. The company is renowned for producing durable and stylish umbrellas that cater to different industries and applications, including commercial spaces, resorts, outdoor cafes, gardens, and more.",
        link: "/Umbrella-Manufacturers-in-Pune"
      },
      {
        imgSrc: "products-img/Inflatable-water-slide-for-kids-and-adults-for-sales.jpg",
        category: ["Inflatable water slides"],
        title: "Inflatable Water Slides",
        description: "Nageshwar Fibre And Fabrication Industries is a renowned name in the field of water slides manufacturing in India. With years of experience and expertise, we design and manufacture a wide range of water slides tailored for water parks, resorts, amusement parks, and recreational facilities. Our slides are engineered for fun, safety, and durability, ensuring an enjoyable experience for everyone.",
        link: "/Water-Slides-Manufacturers-in-India"
      },
      {
        imgSrc: "products-img/Wedding-horse-rath-manufacturer-from-pune.jpg",
        category: ["Wedding Horse Rath"],
        title: "Wedding Horse Rath",
        description: "Nageshwar Fibre And Fabrication Industries is a leading manufacturer of beautifully crafted Wedding Dolis. We specialize in creating traditional and modern designs that add a touch of elegance and grandeur to Indian weddings. Using high-quality materials and intricate craftsmanship, we ensure our Wedding Dolis meet the highest standards of aesthetics and comfort.",
        link: "/Wedding-Doli-Manufacturer-in-Pune"
      },
      ];
    
    return(
        <>
         <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar Fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
           Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries|Fibre Stage manufacturer in Pune |
          Rain Dance Setup Manufacturers in Pune |
          Playground Equipment Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar Fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fibre Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fibre Stage manufacturer in Pune ,Rain Dance Setup Manufacturers in Pune ,Playground Equipment Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

          <section className="breadcrumb-header gallery-image1" id="page" >
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="banner">
                            <h1>Our Blogs</h1>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i className="fas fa-angle-right"></i>
                                </li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>


         {/* ===================  blog section start ================= */}

      <section className="blog py-100-70">
        <div className="container">
          <div className="sec-title sec-title-home-2">
            <div className="row">
              <div className="col-lg-5">
                <h2>Blog</h2>
                <h3>Our blogs</h3>
              </div>
              <div className="col-lg-5 col-md-8 d-flex align-items-center">
                <p>
                  At Nageshwar Fibre and Fabrication, we understand the importance of delivering tailor-made solutions to suit specific business needs.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {blogPosts.map((post) => (
              <div key={post.id} className="col-md-6 col-lg-4">
                <div className="blog-item">
                  <div className="img-box">
                    <Link to={post.link} className="open-item-blog">
                      <i className="fas fa-arrow-right"></i>
                    </Link>
                    <Link to={post.link} className="open-post">
                      <img
                        className="img-fluid"
                        src={post.imgSrc}
                        alt={`Blog ${post.id}`}
                      />
                    </Link>
                    <ul>
                      {post.category.map((cat, index) => (
                        <li key={index}>
                          <Link to={post.link}>{cat}</Link>
                          {index !== post.category.length - 1 && <span>,</span>}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="text-box">
                    <Link to={post.link} className="title-blog">
                      <h5>{post.title}</h5>
                    </Link>
                    <span className="blog-date">{post.date}</span>
                    <p>{post.description}</p>
                    <Link to={post.link} className="link">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
        </>
    )
}

