import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function AnimalStatue() {
  const slides = [
    "slider/Slider for product section-01.jpg",
    "slider/Slider for product section-02.jpg",
  ];
  const imgData = [
    {
        img1: 'Product-Images/FRP-Portable-Cab-manufactures-in-pune.jpg', 
        name: 'FRP Security Cabin',
        price: 'Rs 24,000 / Piece Get Best Price',
        d1: 'Size',
        value1: '4 x 4 feet',
        d2: 'Color',
        value2: 'All colors',
        d3: 'Material',
        value3: 'FRP',
        d4: 'Capacity',
        value4: 'Maximum 2 persons',
        d5: 'Weight',
        value5: '70 kg',
        d6: 'Door Size',
        value6: '2 x 6 feet',
        d7: 'Window Type',
        value7: 'Sliding',
        d8: 'Surface Finish',
        value8: 'Both Side Finish',
        d9:'Height 8 ft',
        value9:'80 kg',
        d10:'Country of Origin',
        value10:'Made in India',
        para: 'Empowered by a squad of diligent and industrious professionals, we have been able to provide an unmatched quality collection of Security Portable Cabins as per the clients’ specifications.',
        para1: 'The FRP Security Cabin is designed for optimal safety and functionality, perfect for use as a guard room. Its modular design allows for easy installation and relocation, making it ideal for various security needs.'
    },
    {
        
            img1: 'Product-Images/Portale-cabin-manufactures-in-pune.jpg', 
            name: 'Portable Office Cabin',
            price: 'Rs 1.8 Lakh / Piece Get Best Price',
            d1: 'Material',
            value1: 'FRP',
            d2: 'Shape',
            value2: 'Rectangular',
            d3: 'Usage/Application',
            value3: 'Guard Room',
            d4: 'Design Type',
            value4: 'Standard',
            d5: 'Seating Capacity',
            value5: '8 seats',
            d6: 'Color',
            value6: 'White',
            para: 'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins, and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.',
            para1: 'The Portable Office Cabin is built using heavy fiber and fabrication material, providing durability and longevity. It is perfect for a variety of applications, offering ample seating and a comfortable workspace in a portable format.'
        
        
    }
    
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to change slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Automatically move the slide
  useEffect(() => {
    const sliderInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(sliderInterval); // Clean up on component unmount
  }, [currentSlide, 3000]);
  return (
    <>
    <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
           Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries|Fiber Stage manufacturer in Pune |
          Rain Dance Setup Manufacturers in Pune |
          Playground Equipment Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Stage manufacturer in Pune ,Rain Dance Setup Manufacturers in Pune ,Playground Equipment Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {index === currentSlide && (
              <img src={slide} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>

      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
