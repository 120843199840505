import './CSS/style.css';
import './CSS/animate.css';
import './CSS/bootstrap.min.css';
import './CSS/lity.min.css';
import './App.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ImageGallery from './ImageGallery';

export default function Gallery(){
    const projectData = [
        {
            imgSrc: "Product-Images/3d-Personalized-crystal-gift-manufactures-in-india.jpg",
            altText: "01 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "3d-Personalized crystal gift manufactures in india",
            link: "/Designer-Wall-Mural-Manufacturers-in-Pune"
        },
        {
            imgSrc: "Product-Images/Amusement-park-equipment-manufactures-in-india.jpg",
            altText: "02 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Amusement park equipment manufactures in india",
            link: "/Water-Park-Equipment-Manufacturers-in-India"
        },
        {
            imgSrc: "Product-Images/Shivaji-maharaj-statue-maker-in-pune.jpg",
            altText: "03 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Shivaji Maharaj Statue Maker in pune",
            link: "/Shivaji-Maharaj-Statue-Maker-in-Pune"
        },
        {
            imgSrc: "Product-Images/Swimmg-pool-maufcatures-in-pn.jpg",
            altText: "04 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Swimming Pool Manufactures In Pune",
            link: "/Fiberglass-Swimming-Pool-Contractors-in-Pune"
        },
        {
            imgSrc: "Product-Images/Water-play-system-manufacrtures-in-pune.jpg",
            altText: "05 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Water Play System Manufactures In Pune",
            link: "/Water-Park-Equipment-Manufacturers-in-India"
        },
        {
            imgSrc: "Product-Images/Playground-equipment-manufactures-in-india.jpg",
            altText: "06 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Playground equipment manufactures in pune",
            link: "/Playground-Slide-Manufacturers-in-Pune"
        },
        {
            imgSrc: "Product-Images/FRP-Animal-Manufactures-in-pune.jpg",
            altText: "06 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Animal and fiber statue manufacturers in pune",
            link: "/Animal-and-fiber-statue-manufacturers-in-pune"
        },
        {
            imgSrc: "Product-Images/Marble-kalash-manufactures-in-pune.jpg",
            altText: "06 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Marble kalash manufactures in pune",
            link: "/Plastic-dustbin-manufacturers-in-Pune"
        },
        {
            imgSrc: "Product-Images/Inflatable-swimming-pool-manufactures-in-india.jpg",
            altText: "06 Work",
            tags: ["Nageshwar Fibre & Fibrication Industries"],
            title: "Inflatable swimming poolmanufactures in india",
            link: "/FRP-Swimming-Pool-Manufacturers-in-India"
        },
        // ====================
        {
          imgSrc: "Product-Images/Merrt-go-round-swing-manufcature-in-pune.jpg",
          altText: "06 Work",
          tags: ["Nageshwar Fibre & Fibrication Industries"],
          title: "Merry go round swing manufcature in pune",
          link: "/Playground-Equipment-Manufacturers-in-India"
      },
      {
        imgSrc: "products-img/Garden-bench-manufactures-in-pune.jpg",
        altText: "06 Work",
        tags: ["Nageshwar Fibre & Fibrication Industries"],
        title: "Garden bench manufactures in pune",
        link: "/Fibre-School-Furniture-Manufacturers-in-Pune"
    },
    {
      imgSrc: "products-img/Fiber-Dome-Manufacture-in-pune.jpg",
      altText: "06 Work",
      tags: ["Nageshwar Fibre & Fibrication Industries"],
      title: "Fiber Dome Manufacture in pune",
      link: "/frp-Dome-Manufacturers-in-India"
  },
  {
    imgSrc: "products-img/Fiber-pillar-manufactures-in.jpg",
    altText: "06 Work",
    tags: ["Nageshwar Fibre & Fibrication Industries"],
    title: "Fiber Pillar Manufactures In",
    link: "/Fiber-Pillar-Manufacturers-in-Pune"
},
{
  imgSrc: "products-img/Commercial-dinning-table-and-chairs-manufactures-in-pune.jpg",
  altText: "06 Work",
  tags: ["Nageshwar Fibre & Fibrication Industries"],
  title: "Commercial dinning table and chairs manufactures in pune",
  link: "/Fabrication-Companies-in-Pune"
},
{
  imgSrc: "products-img/Elephant-Statue-manufacture-in-pune.jpg",
  altText: "06 Work",
  tags: ["Nageshwar Fibre & Fibrication Industries"],
  title: "Elephant Statue manufacture in pune",
  link: "/Animal-and-fiber-statue-manufacturers-in-pune"
},

    ];

    const WorkItem = ({ imgSrc, altText, tags, title, link }) => (
        <div className="col-md-6 col-lg-4">
            <div className="item-work">
                <span></span>
                <div className="img-box">
                    <img className="img-fluid gallery-item-img" src={imgSrc} alt={altText} />
                </div>
                <div className="hover-box">
                    <Link to={link} className="open-item-work">
                        <i className="fas fa-arrow-right"></i>
                    </Link>
                    <div className="text-box">
                        <div className="tags">
                            {tags.map((tag, index) => (
                                <Link to={link} key={index}>{tag}{index < tags.length - 1 ? ',' : ''} </Link>
                            ))}
                        </div>
                        <h4><Link to={link}>{title}</Link></h4>
                    </div>
                </div>
            </div>
        </div>
    );
    
    
    return(
        <>
         <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar Fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
           Contact: +91 8793191191,+91 9762431883|Nageshwar Fibre And Fabrication Industries|Tube Slide Water Park Contractor in Pune | Fiber Pillar
          Manufacturers in Pune | Water-Fountain-Manufacturers-in-Pune | frp
          Dome Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar Fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Pillar Manufacturers in Pune,Water-Fountain-Manufacturers-in-Pune,frp Dome Manufacturers in India,Plastic dustbin manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
           {/* <!-- :: Breadcrumb Header --> */}
          <section className="breadcrumb-header gallery-image" id="page" >
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="banner">
                            <h1>Gallery</h1>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i className="fas fa-angle-right"></i>
                                </li>
                                <li>Gallery</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        {/* ====================== main section ================= */}
        <section className="work py-100-70">
        <div className="container">
            <div className="sec-title">
                <div className="row">
                    <div className="col-lg-4">
                        <h2>Bringing the art to the cart</h2>
                        <h3>Nageshwar Fibre & Fibrication Industries</h3>
                    </div>
                    <div className="col-lg-5 col-md-8 d-flex align-items-center">
                        <p>
                        Nageshwar Fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products. We are also one of the most sought after suppliers in the same domain of work.
                        </p>                   
                         </div>
                    <div className="col-lg-3 col-md-4 d-flex align-items-center justify-content-center text-center">
                        <Link to="/products" className="btn-1 btn-3">View All Products</Link>
                    </div>
                </div>
            </div>
            <div className="row">
                {projectData.map((project, index) => (
                    <WorkItem key={index} {...project} />
                ))}
            </div>
        </div>
      </section>


      <ImageGallery></ImageGallery>

        </>
    )
}