import { Link } from "react-router-dom";
import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import React from "react";
import "./App.css";
import Slider from 'react-slick';
import { Helmet } from "react-helmet";

export default function Product() {
  const products = [
    {
      id: 1,
      name: "2-Seater Paddle Boat",
      price: "Rs 60,000 /Piece",
      imgSrc: "gallery/2-seater-boat.jpg",
      alt: "01 Shop",
    },
    {
      id: 2,
      name: "Decorative Garden Fountain",
      price:"Rs 12,000/Piece",
      imgSrc: "gallery/fountain.jpg",
      alt: "02 Shop",
    },
    {
      id: 3,
      name: "Decoration Fibre Pillars",
      price: "Rs 1,000  / sq ft",
      imgSrc: "gallery/FRP Domes.jpg",
      alt: "Fibre Pillar",
    },
    {
      id: 4,
      name: "Garden  Swing",
      price: "Rs 30  / Unit",
      imgSrc: "gallery/garden swing.jpg",
      alt: "Garden Swing",
    },
    {
      id: 5,
      name: "Fibre Food Van",
      price: "Rs 2,800  / Piece",
      imgSrc: "gallery/food van.jpg",
      alt: "Food Van",
    },
    {
      id: 5,
      name: "FRP Wave Pool Manufactures",
      price: "Rs 60,000  / Piece",
      imgSrc: "gallery/Portable swimming pool.jpg",
      alt: "Animal Shaped Dustbin",
    },
     

  ];


  const images = [
    'Product-Images/2-Seater FRP Swan Paddle Boat.jpg',
    'Product-Images/3d-Personalized-crystal-gift-manufactures-in-india.jpg',
    'Product-Images/4-Seater FRP Paddle Boat.jpg',
    'Product-Images/Animal Shape FRP Dustbin.jpg',
    'Product-Images/Commercial-dinning-table-and-chairs-manufacturesin-pune.jpg',
    'Product-Images/Elephant-statue-manufactures-in-pune.jpg'
  ];


  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Adjust for smaller screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show one slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
};



const projects = [
  {
    name: 'Project 1',
    image: "Product-Images/FRP Wave Slide.jpg",
  },
  {
    name: 'Project 2',
    image: 'Product-Images/Playground-slides-manufactures-in-pune.jpg',
  },
  {
    name: 'Project 3',
    image: 'Product-Images/Roller-slide-playground-equipment-manufactures-in-pune.jpg',
  },
  {
    name: 'Project 4',
    image: 'Product-Images/Playground-equipment-manufactures-in-india.jpg',
  },
  {
    name: 'Project 5',
    image: 'Product-Images/Park-slides-manufactures-in-pune.jpg',
  },

  
  
];

const project2 = [
  {
    name: 'Project 1',
    image: "Product-Images/Wedding-horse-rath-manufactures-from-pune.jpg",
  },
  {
    name: 'Project 2',
    image: 'Product-Images/Wedding-doli-manufactures-in-pune.jpg',
  },
  {
    name: 'Project 3',
    image: 'Product-Images/Wedding Jeep rath.jpg',
  },
  {
    name: 'Project 4',
    image: 'Product-Images/Wedding horse baggi.jpg',
  },
  {
    name: 'Project 5',
    image: 'Product-Images/Wedding Jeep Rath 1.jpg',
  },
]


const project3 = [
  {
    name: 'Project 1',
    image: "Product-Images/Fiberglass-swimming-pool-contractor-in-pune.jpg",
  },
  {
    name: 'Project 2',
    image: 'Product-Images/FRP-Swimming-pool-manufactures-in-india.jpg'
  },
  {
    name: 'Project 3',
    image: 'Product-Images/Water-slides-manufactures-in-india.jpg',
  },
  {
    name: 'Project 4',
    image: 'Product-Images/Water-play-system-manufacrtures-in-pune.jpg',
  },
  {
    name: 'Project 5',
    image: 'Product-Images/Water-park-equipment-manufactures-in-pune.jpg',
  },
]




  
  return (
    <>
     <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar Fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
         Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries|Outdoor Swing Manufacturers in Pune | Wedding Doli Manufacturer in Pune | Shivaji Maharaj Statue Maker in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar Fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fibre Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Outdoor Swing Manufacturers in Pune, Wedding Doli Manufacturer in Pune, Shivaji Maharaj Statue Maker in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* <!-- :: Breadcrumb Header --> */}
      <section
        className="breadcrumb-header gallery-image"
        id="page"
        
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Our Products</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Our Products</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="shop-page py-100-70">
        <div className="container">
          <div className="row">
            {products.map((product) => (
              <div className="col-md-6 col-lg-4" key={product.id}>
                <div className="shop-item">
                  <div className="item-img">
                    <span></span>
                    <img
                      className=""
                      src={product.imgSrc}
                      alt={product.alt}
                    />
                    <div className="box-more d-flex align-items-center">
                      <Link to="/contact" className="btn-1 btn-3">
                           Buy Now
                      </Link>
                    </div>
                  </div>
                  <div className="item-text text-center">
                    <Link to="/single-product" className="open-item-shop">
                      <h4>{product.name}</h4>
                    </Link>
                    <span>{product.price}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* ==================Product section =================== */}
          <section className="wave-section container-fluid">
          <div className="wave-slider-container">
      <div className="wave-slider">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`wave-img-${index}`} className="wave-slider-image" />
        ))}
        {/* Duplicate for continuous effect */}
        {images.map((image, index) => (
          <img key={index + images.length} src={image} alt={`wave-img-${index + images.length}`} className="wave-slider-image" />
        ))}
      </div>
      
    </div>
    </section>
          {/* ===================== */}
        </div>
      </section>


      <div className="project-carousel">
      <h2>Playground Slides</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image img-slide">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            </div>
          </div>
        ))}
      </Slider>

      <h2>Wedding Doli</h2>
      <Slider {...settings}>
        {project2.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image img-slide">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            </div>
          </div>
        ))}
      </Slider>

      <h2>Water Park Equipments</h2>
      <Slider {...settings}>
        {project3.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image img-slide">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    </>
  );
}
