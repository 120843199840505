import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function TacklessTrain(){
    const imgData = [
        {
            "img1": "Product-Images/Trackless-Train-manufactures-in-pune.jpg",
            "name": "FRP Trackless Train",
            "price": "Rs 4.5 Lakh / Piece",
            "d1": "Material",
            "value1": "FRP (Fiber Reinforced Plastic)",
            "d2": "Battery Charging Time",
            "value2": "2 Hours",
            "d3": "Battery Charge Time",
            "value3": "2 Hours",
            "d4": "Color",
            "value4": "Red, Blue, Yellow",
            "d5": "Number of Bogies",
            "value5": "4",
            "d6": "Age Group",
            "value6": "All Age Groups",
            "d7": "Height",
            "value7": "15.3 cm",
            "para": "Nageshwar Fibre & Fabrication Industries offers a high-quality FRP Trackless Train, designed for water parks, hotels, and resorts. This train, customizable to client needs, features 4 bogies, vibrant colors, and operates on battery power with a charge time of just 2 hours. Crafted with international standards using high-grade materials, this durable trackless train is suitable for all age groups."
          }
          
    ]
    return(
        <>
        <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
           Contact: +91 8793191191, +91 9762431883|Nageshwar Fibre And Fabrication Industries| Trackless Train Manufacturers in Pune | frp Jeep Rath
          Manufacturers in Pune | frp Portable Toilets and Urinals Manufacturers
          in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Trackless Train Manufacturers in Pune , frp Jeep Rath Manufacturers in Pune
          ,frp Portable Toilets and Urinals Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

           <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Trackless Train </h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Trackless Train Manufacturers in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      
                {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
          Trackless Train Manufacturers in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
         <p>Nageshwar Fibre And Fabrication Industries is a leading manufacturer of high-quality FRP Trackless Trains in Pune. These trains are designed for use in amusement parks, resorts, malls, and public spaces. Known for their durability, aesthetic appeal, and customization options, the trackless trains are made using premium Fiber Reinforced Plastic (FRP) materials, ensuring they meet international standards.</p>
         <p>With years of experience in manufacturing a wide range of outdoor and playground equipment, Nageshwar Fibre And Fabrication Industries ensures that each product is crafted with precision and innovation. The trackless trains are safe, easy to operate, and are designed to provide an exciting and comfortable experience for passengers.</p>
        </div>
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


        {/*  ================= Contact Details ==================== */}
        <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        </>
    )
}