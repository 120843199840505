import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";




export default function FRPBoatManufactureInIndia(){
    const imgData = [
        {
          img1: "Product-Images/Paddl-boat-manufactures-in-india.jpg",
          name: "FRP Paddle Boat",
          price: "Rs 55,000 / Piece",
          d1: "Power Source",
          value1: "Paddle",
          d2: "Color",
          value2: "All Colour Available",
          d3: "Material",
          value3: "FRP",
          d4: "Size",
          value4: "12 feet",
          d5: "Seating Capacity",
          value5: "2 People Seat",
          d6: "Country of Origin",
          value6: "Made in India",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins, and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw materials and advanced methodology in order to ensure superiority in quality.",
        },
        {
          img1: "Product-Images/4-Seater FRP Paddle Boat.jpg",
          name: "4 Seater FRP Paddle Boat",
          price: "Rs 70,000 / Piece",
          d1: "Minimum Order Quantity",
          value1: "2 Piece",
          d2: "Length",
          value2: "11.5 ft",
          d3: "Power Source",
          value3: "Manual",
          d4: "Width",
          value4: "5.5 ft",
          d5: "Color",
          value5: "Multicolour",
          d6: "Height",
          value6: "4 ft",
          d7: "Size/Dimension",
          value7: "11.5*5.5*4 ft",
          d8: "Seating Capacity",
          value8: "4 Seater",
          d9: "Capacity",
          value9: "130 Kg",
          para: "Nageswar Fiber & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbins, Portable Cabins, and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw materials and advanced methodology to ensure superiority in quality.",
        },
        {
          img1: "Product-Images/4-Seater Swan Paddle Boat.jpg",
          name: "FRP 4 Seater Swan Paddle Boat",
          price: "Rs 60,000 / Piece",
          d1: "Minimum Order Quantity",
          value1: "5 Piece",
          d2: "Power Source",
          value2: "Manual",
          d3: "Material",
          value3: "FRP FIBER",
          d4: "Color",
          value4: "Multicolour",
          d5: "Size/Dimension",
          value5: "12*5.5*3 ft",
          d6: "Number Of Seater",
          value6: "4",
          d7: "Seating Capacity",
          value7: "4",
          d8: "Weight",
          value8: "150 kg",
          d9: "Pattern",
          value9: "Swan Boat",
          d10: "Height",
          value10: "3 Ft",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbins, Portable Cabins, and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw materials and advanced methodology to ensure superiority in quality.",
        },
       
      ];
    return(
        <>
 <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191,+91 9762431883| Nageshwar Fibre And Fabrication Industries|Fiber Pillar Manufacturers in Pune |
          Water-Fountain-Manufacturers-in-Pune | frp Dome Manufacturers in India
          | Plastic dustbin manufacturers in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Pillar Manufacturers in Pune,Water-Fountain-Manufacturers-in-Pune,frp Dome Manufacturers in India,Plastic dustbin manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
<section
        className="breadcrumb-header"
        id="page"
        style={{ backgroundImage: `url(images/header/02_header.jpg)` }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Frp Paddle Boat</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>FRP Boat Manufacturers in India</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

          {/*   ================= Start the Product ===================== */}
      <div className="mysection1">


      <div className="container product-section">
          <h1>
          FRP Boat Manufacturers in India – Nageshwar Fibre And
            Fabrication Industries
          </h1>
        <p>Nageshwar Fibre And Fabrication Industries is a renowned manufacturer of FRP (Fibre-Reinforced Plastic) boats in India, offering high-quality, durable, and customizable boats for a variety of applications. With a focus on innovation, safety, and strength, we produce boats that are lightweight, corrosion-resistant, and designed for long-lasting performance in various water environments.</p>
        </div>   

        {/* Image Section */}

     
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>



       {/*  ================= Contact Details ==================== */}
       <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        </>
    )
}