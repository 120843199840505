import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";

export default function RainDanceSetupManufactureInPune() {
  const imgData = [
    {
      img1: "Product-Images/Rain-Dance-Setup-manufactures-in-pune.jpg",
      name: "Rain Dance Setup",
      price: "Rs 80,000 / Event",
      d1: "Minimum Order Quantity",
      value1: "1 Event",
      d2: "Usage/Application",
      value2: "Rain dance parties",
      d3: "Size",
      value3: "Diameter - 15 feet",
      d4: "Material",
      value4: "Fibre and Bronze Nozzle",
      d5: "Location Type",
      value5: "All over India",
      d6: "Shape Type",
      value6: "Round and Hexagonal",
      d7: "Water Supply",
      value7: "Bronze nozzle",
      para: "Nageshwar Fibre & Fabrication Industries is dedicated to providing high-quality solutions for rain dance parties. Our setups are designed and manufactured using superior materials and advanced techniques to ensure a delightful experience for all participants.",
    },
    {
      img1: "images/Rain Dance Setup/rain-dance-500x500.webp",
      name: "Rain Dance Setup",
      price: "Rs 1.5 Lakh / Set",
      d1: "Event Type",
      value1: "Theme Party",
      d2: "Party Theme",
      value2: "Bollywood",
      d3: "Size",
      value3: "2 x 20 ft",
      d4: "Usage/Application",
      value4: "Water park",
      d5: "Event Location",
      value5: "Mumbai",
      d6: "Height",
      value6: "10 ft round",
      d7: "Weight",
      value7: "170 kg with motor fitting",
      para: "Nageshwar Fibre & Fabrication Industries is renowned for manufacturing a high-quality range of products, including rain dance setups. Our products are designed with heavy-duty materials and superior craftsmanship to ensure outstanding performance and durability.",
    },

    {
      img1: "images/Rain Dance Setup/frp-raindance-500x500.webp",
      name: "FRP Raindance Setup",
      price: "Rs 7 Lakh / Set",
      d1: "Shape",
      value1: "Square",
      d2: "Color",
      value2: "Multicolor",
      d3: "Size",
      value3: "30 x 30 ft",
      d4: "Is It Waterproof",
      value4: "Waterproof",
      d5: "Is It Anti Corrosive",
      value5: "Anti Corrosive",
      d6: "Usage/Application",
      value6: "Water Park",
      d7: "I Deal In",
      value7: "New Only",
      d8: "Material",
      value8: "FRP Fiber, GI Pipe",
      para: "Nageshwar Fibre & Fabrication Industries is renowned for manufacturing a high-quality range of products, including the FRP Raindance Setup. Our products are designed and manufactured by experienced professionals using high-grade materials to ensure superior quality and durability.",
    },
    {
      img1: "images/Rain Dance Setup/frp-octopus-raindance-500x500.webp",
      name: "FRP Octopus Raindance",
      price: "Rs 4.2 Lakh / Piece",
      d1: "Material",
      value1: "FRP",
      d2: "Color",
      value2: "All Colors Available",
      d3: "Exit",
      value3: "Pool",
      d4: "Usage/Application",
      value4: "Water Park, Hotels, Resort",
      d5: "Design",
      value5: "Triangle Shape",
      d6: "Bucket Splashing Water Capacity",
      value6: "Yes",
      d7: "Standard",
      value7: "ASTM",
      d8: "Corrosion Resistance",
      value8: "Yes",
      d9: "Maximum Shell Length",
      value9: "As per requirement",
      d10: "Covered Length",
      value10: "As per requirement",
      para: "Nageswar Fiber and Fabrication offers the FRP Octopus Raindance, an essential addition for any water park, hotel, or resort. Manufactured according to international standards using high-grade materials, this setup is designed for durability and performance, ensuring maximum enjoyment for guests.",
    },
  ];
  return (
    <>
      <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Nageshwar Fibre And Fabrication Industries| Contact: +91 8793191191,
          +91 9762431883| Trackless Train Manufacturers in Pune | frp Jeep Rath
          Manufacturers in Pune | frp Portable Toilets and Urinals Manufacturers
          in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Trackless Train Manufacturers in Pune , frp Jeep Rath Manufacturers in Pune
          ,frp Portable Toilets and Urinals Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

     <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Rain Dance Setup</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li> Rain Dance Setup Manufacturers in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
            Rain Dance Setup Manufacturers in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
          <p>
            Nageshwar Fibre And Fabrication Industries is a leading manufacturer
            of Rain Dance setups in Pune, offering innovative and high-quality
            solutions for creating an enjoyable and thrilling water dance
            experience at events, parties, and recreational venues. Our rain
            dance setups are designed to provide fun, excitement, and safety,
            making them ideal for various occasions, including parties,
            festivals, and corporate events.
          </p>
        </div>

        {imgData.map((e) => {
          return (
            <>
              <div className="mysection1">
                <div className="product-container">
                  <motion.div
                    className="image-container"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                  >
                    <img
                      src={e.img1}
                      className="productimg" // Replace with actual product image
                      alt="FRP Elephant Statue"
                    />
                  </motion.div>

                  {/* Product Info Section */}
                  <motion.div
                    className="product-details"
                    initial={{ x: -100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                  >
                    <h1>{e.name}</h1>
                    <p className="price">{e.price}</p>
                    <div className="details-table">
                      <table>
                        <tbody>
                          <tr>
                            <th>{e.d1}</th>
                            <td>{e.value1}</td>
                          </tr>
                          <tr>
                            <th>{e.d2}</th>
                            <td>{e.value2}</td>
                          </tr>
                          <tr>
                            <th>{e.d3}</th>
                            <td>{e.value3}</td>
                          </tr>
                          <tr>
                            <th>{e.d4}</th>
                            <td>{e.value4}</td>
                          </tr>
                          <tr>
                            <th>{e.d5}</th>
                            <td>{e.value5}</td>
                          </tr>
                          <tr>
                            <th>{e.d6}</th>
                            <td>{e.value6}</td>
                          </tr>
                          <tr>
                            <th>{e.d7}</th>
                            <td>{e.value7}</td>
                          </tr>
                          <tr>
                            <th>{e.d8}</th>
                            <td>{e.value8}</td>
                          </tr>
                          <tr>
                            <th>{e.d9}</th>
                            <td>{e.value9}</td>
                          </tr>
                          <tr>
                            <th>{e.d10}</th>
                            <td>{e.value10}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </motion.div>
                </div>
                <div className="content-section">
                  <div className="paragraph-section">
                    <p>{e.para}</p>
                    <p>{e.para1}</p>
                  </div>
                  <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                    <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                  </motion.div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      {/*  ================= Contact Details ==================== */}
      <div className="container">
        <div className="col-12 col-lg-6 contact-box p-3 mb-4">
          <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
          <p>
            If you're looking for the best Fiber and Fabrication Product,
            contact Nageshwar Fibre And Fabrication Industries today.{" "}
          </p>
          <p>
            <a href="" className="">
              Mobile No :
            </a>
            <a href="tel:+91 8793191191"> +91 8793191191 </a>
            <a href="tel:+91 9762431883"> +91 9762431883 </a>
          </p>

          <a href="mailto:info@nageshwarfiber.com">
            Email Id : info@nageshwarfiber.com
          </a>
        </div>
      </div>
    </>
  );
}
