import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import { useState, React } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BlogSection from "./BlogSection";
import { Link } from "react-router-dom";
import { img, link } from "framer-motion/client";
import { GiFactory } from "react-icons/gi";
import { Helmet } from "react-helmet";
import { FaCog } from 'react-icons/fa';
import "./App.css";

const Home = () => {



  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const features = [
    "Great Support",
    "Unique Technology",
    "Awesome Team",
    "Smart Work",
    "Quality Materials",
  ];

  const servicesData = [
    {
      img: "gallery/Elephant statue.jpg",
      title: " Elephant Statue",
      description:
        "Designed with intricate detailing, this statue is not only lightweight and durable but also weather-resistant, making it perfect for both indoor and outdoor decor.",
      link: "/animal-statue-manufacturer-in-pune",
    },
    {
      img: "gallery/Portable swimming pool.jpg",
      title: "FRP Swimming Pool",
      description:
        "Experience the luxury of a swimming pool without the hassle of permanent installation with our FRP  Portable Swimming Pool. Lightweight, durable, and easy to set up, this pool is designed for both residential and commercial use.",
      link: "/swimming-pool-manufacturers-in-india",
    },
    {
      img: "gallery/wedding-horse-rath.jpg",
      title: "Wedding Horse Rath",
      description:
        "Make your wedding day unforgettable with our stunning Wedding Horse Rath, designed to bring a touch of royal grandeur to your celebration. Crafted with intricate detailing and made from high-quality materials, this beautifully adorned rath ensures a regal entrance for the groom.",
      link: "/wedding-baggi-manufacturers-in-india",
    },
    {
      img: "gallery/wedding stage manufacture in pune.jpg",
      title: "Wedding Stage Decorator",
      description:
        "From elegant floral arrangements to intricate draping and lighting, our team pays attention to every detail to ensure your stage becomes the centerpiece of your celebration. Whether you desire a traditional, modern, or themed setup, we work closely with you to bring your dream wedding stage to life, making your special day truly unforgettable.",
      link: "/wedding-fibre-stage-manufacturer-in-pune",
    },
    {
      img: "gallery/Fibre umbrella.jpg",
      title: "Fibre Water Park Umbrella",
      description:
        "Enhance your water park experience with our high-quality Fibre Water Park Umbrella, designed to provide optimal shade and comfort for guests. Made from durable, weather-resistant materials, this umbrella offers reliable protection from the sun while adding a stylish touch to your aquatic environment.",
      link: "/garden-umbrella-manufacturers-in-pune",
    },
    {
      img: "gallery/dustbin.jpg",
      title: "Automotive Manufacturing",
      description:
        "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins, and much more.",
      link: "/dustbin-manufacturer-in-pune",
    },
  ];

  const projects = [
    {
      name: "Swimming Pool",
      image: "gallery/swimming pool.jpg",
    },
    {
      name: "Shivaji Maharaj Statue",
      image: "gallery/shivaji maharaj statue.jpg",
    },
    {
      name: "Fibre Pillar",
      image:"gallery/FRP pillars.jpg",
    },
    {
      name: "Garden Dustbin",
      image: "gallery/dustbin.jpg",
    },
    {
      name: "Sports Trampolin",
      image: "gallery/Trampoline.jpg",
    },
    {
      name: "Tube Slider",
      image: "gallery/tube slider.jpg",
    },
    {
      name:'Garden Fountain',
      image:'gallery/fountain.jpg'
    },
    {
      name:'FRP Portable Toilets',
      image:'gallery/portable toilets.jpg'
    },
    {
      name:'Paddle Boat',
      image:'gallery/2-seater-boat.jpg'
    }

  ];

  const teamMembers = [
    {
      name: "Anwar Ramadan",
      position: "Account Manager",
      imgSrc: "images/team/01_team.jpg",
      alt: "01 team",
    },
    {
      name: "Osama Bakri",
      position: "Founder & CEO",
      imgSrc: "images/team/02_team.jpg",
      alt: "02 team",
    },
    {
      name: "Sana El-Shamy",
      position: "Senior Engineer",
      imgSrc: "images/team/03_team.jpg",
      alt: "03 team",
    },
  ];

  const teamMembers2 = [
    {
      name: "Animal and Fibre statue manufacturing ",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "Product-Images/Animal-and-fiber-statue-manufactures-in-pune.jpg",
      socials: [
        "fab fa-facebook-f",
      ],
    },
    {
      name: "Playground Slides",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "Product-Images/Playground-slides-manufactures-in-pune.jpg",
      socials: [
      
        "fab fa-twitter",
       
      ],
    },
    {
      name: "Swimmingg Pool",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "products-img/Park-Slides-manufacturerin-pune.jpg",
      socials: [
        "fab fa-facebook-f",
      
      ],
    },

    {
      name: "Trampoline",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "gallery/Trampoline.jpg",
    
    },

    {
      name: "Wedding Stage",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "gallery/wedding stage manufacture in pune.jpg",
    
    },
    {
      name: "Fiber Food Van",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "Product-Images/Food-van-manufactures-in-pune.jpg",
    
    },
  ];

  const statistics = [
    {
      icon: "flaticon-worker-1",
      counter: "7,112",
      description: "Projects And Residentials Completed in 2021",
    },
    {
      icon: "flaticon-worker",
      counter: "7,003",
      description: "Qualified Employees And Workers With Us",
    },
    {
      icon: "flaticon-globe",
      counter: "40",
      description: "Years Of Experience In The Factory and Manufacturing",
    },
  ];

  const projectData = [
    {
      imgSrc: "gallery/wedding-horse-rath.jpg",
      altText: "wedding horse rath",
      tags: ["Nageshwar Fibre & Fibrication Industries"],
      title: "Wedding Horse Rath",
      link: "/Wedding-Doli-Manufacturer-in-Pune",
    },
    {
      imgSrc: "gallery/kolambus ride.jpg",
      altText: "02 Work",
      tags: ["Nageshwar Fibre & Fibrication Industries"],
      title: "kolambus ride",
      link: "/Family-Rides-at-Thorpe-Park-Manufacturers-in-India",
    },
    {
      imgSrc: "gallery/industrial shed.jpg",
      altText: "03 Work",
      tags: ["Nageshwar Fibre & Fibrication Industries"],
      title: "Industrial Shed Fabrication",
      link: "/Fabrication-Companies-in-Pune",
    },
    {
      imgSrc: "gallery/garden swing.jpg",
      altText: "04 Work",
      tags: ["Nageshwar Fibre & Fibrication Industries"],
      title: "Fibre Garden Swing",
      link: "/frp-Dome-Manufacturers-in-India",
    },
    {
      imgSrc: "gallery/fountain.jpg",
      altText: "05 Work",
      tags: ["Nageshwar Fibre & Fibrication Industries"],
      title: "Garden Fountain",
      link: "/Water-Fountain-Manufacturers-in-Pune",
    },
    {
      imgSrc: "gallery/shivaji maharaj statue.jpg",
      altText: "06 Work",
      tags: ["Nageshwar Fibre & Fibrication Industries"],
      title: "Shivaji Maharaj Statue",
      link: "/Shivaji-Maharaj-Statue-Maker-in-Pune",
    },
  ];

  const WorkItem = ({ imgSrc, altText, tags, title, link }) => (
    <div className="col-md-6 col-lg-4">
      <div className="item-work">
        <span></span>
        <div className="img-box">
          <img
            className="img-fluid gallery-item-img"
            src={imgSrc}
            alt={altText}
          />
        </div>
        <div className="hover-box">
          <Link to={link} className="open-item-work">
            <i className="fas fa-arrow-right"></i>
          </Link>
          <div className="text-box">
            <div className="tags">
              {tags.map((tag, index) => (
                <Link to={link} key={index}>
                  {tag}
                  {index < tags.length - 1 ? "," : ""}{" "}
                </Link>
              ))}
            </div>
            <h4>
              <Link to={link}>{title}</Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    industry: "0",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form Data:", formData);
  };

  const settings1 = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 2000, 
    arrows: false, 
  };

  const sponsors1 = [
    { id: 1, src: "images/sponsors/01_sponsors.png", alt: "01 Sponsors" },
    { id: 2, src: "images/sponsors/02_sponsors.png", alt: "02 Sponsors" },
    { id: 3, src: "images/sponsors/03_sponsors.png", alt: "03 Sponsors" },
    { id: 4, src: "images/sponsors/04_sponsors.png", alt: "04 Sponsors" },
  ];

  const settings3 = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop sliding
    speed: 500, // Transition speed
    slidesToShow: 5, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Adjust for smaller screens
        },
      },
    ],
  };






  // ================ Testimonial Section ===========
  const testimonialsData = [
    {
      name: "Sneha Patil, Pune",
      img: "images/testimonial/testL1.png",
      feedback:
        "Working with Nageshwar Fibre & Fabrication Industries has been a transformative experience for us. Their attention to detail and commitment to quality have resulted in exceptional products that consistently meet our needs. Their team is knowledgeable, responsive, and dedicated to ensuring our satisfaction. Highly recommended!",
    },
    {
      name: "Rahul Mehta",
      img: "images/testimonial/testG1.png",
      feedback:
        "Nageshwar Fibre & Fabrication Industries stands out in the industry for its customer-centric approach. Every project we’ve undertaken with them has been a breeze due to their proactive communication and timely delivery. Their products are of top-notch quality, and we couldn't be happier with our partnership.",
    },
    {
      name: "Vikram Desai",
      img: "images/testimonial/testG2.png",
      feedback:
        "Partnering with Nageshwar Fibre & Fabrication Industries has been a game-changer for our business. Their products reflect superior craftsmanship, and the team goes above and beyond to tailor solutions that fit our specific requirements. Their dedication to excellence is evident in every interaction.",
    },
    {
      name: "Sunita Joshi",
      img: "images/testimonial/testL2.png",
      feedback:
        "We have been sourcing our fibre and fabrication needs from Nageshwar for over three years, and their consistency in delivering quality products is unparalleled. Their ethical practices and commitment to sustainability resonate with our values, making them our preferred supplier. I highly endorse their services!",
    },
    {
      name: "Priya Kapoor",
      img: "images/testimonial/testL3.png",
      feedback:
        "Nageshwar Fibre & Fabrication Industries has exceeded our expectations in every project. Their innovative approach to fabrication has helped us create products that not only meet industry standards but also contribute to a greener future. The team’s collaborative spirit makes them a pleasure to work with!",
    },

    {
      name: "Rajesh Kulkarni",
      img: "images/testimonial/testG3.png",
      feedback:
        "As an engineer, I value precision and quality, and Nageshwar Fibre & Fabrication Industries delivers on both fronts. Their products are robust and reliable, perfect for our construction projects. The team is always available to assist and provide solutions, making them an invaluable partner for us.",
    },
    {
      name: " Anjali Verma",
      img: "images/testimonial/testL4.png",
      feedback:
        '"Nageshwar Fibre & Fabrication Industries is our go-to partner for all our fibre needs. Their professionalism and dedication to customer satisfaction are commendable. The quality of their products has allowed us to enhance our offerings significantly. I cannot recommend them enough!"',
    },
  ];

  const testimonialSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, 
    pauseOnHover: false, 
    arrows: false,
  };

  return (
    <>
      <Helmet>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar Fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfibre.com/",
            logo: "https://www.nageshwarfibre.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.8",
              ratingCount: "1574",
            },

            email: "info@nageshwarfibre.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfibre.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfibre.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfibre.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar Fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fibre Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Playground Slide Manufacturers in Pune,Water Park Slide Manufacturers in India, Park Slides Manufacturer in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fibre and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfibre.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfibre.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <header className="header header-home-1 myslider" id="page">
    
      <div>
          <Slider {...settingsSlider}>
            <div className="myslider slide1">
              <img src="slider/Slider1.jpg" alt="slide1" />
            </div>
            <div className="myslider slide1">
              <img src="slider/Slider2.jpg" alt="slide 2" />
            </div>
            <div className="myslider slide1">
              <img src="slider/Slider3.jpg" alt="slide 3" />
            </div>
            <div className="myslider slide1">
              <img src="slider/Slider4.jpg" alt="slide 4" />
            </div>
            <div className="myslider slide1">
              <img src="slider/Slider5.jpg" alt="slide 5" />
            </div>
            <div className="myslider slide1">
              <img src="slider/Slider6.jpg" alt="slide 6" />
            </div>
            <div className="myslider slide1">
              <img src="slider/Slider7.jpg" alt="slide 7" />
            </div>

          </Slider>
        </div>
      </header>


    <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2000">
      <img src="slider/Slider1.jpg" class="d-block w-100" alt="Slider1"/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="slider/Slider2.jpg" class="d-block w-100" alt="Slider2"/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="slider/Slider3.jpg" class="d-block w-100" alt="Slider3"/>
    </div> 
    <div class="carousel-item" data-bs-interval="2000">
      <img src="slider/Slider4.jpg" class="d-block w-100" alt="Slider4"/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
        <img src="slider/Slider5.jpg" class="d-block w-100" alt="Slider5"/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="slider/Slider6.jpg" class="d-block w-100" alt="Slider6"/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="slider/Slider7.jpg" class="d-block w-100" alt="Slider7"/>
    </div>
   
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>  
     




      {/* ================================== */}

      <section className="about-us my-about-section" id="about-us-section">
        <div className="container">
          <div className="row">
            {/* Left side image section */}
            <div className="col-lg-6">
              <div className="row img-box">
                <div className="col">
                  <div className="one">
                    <div className="about-img">
                      <img
                        className="img-fluid"
                        src="images/about/01about.jpg"
                        alt="01 About"
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="two">
                    <div className="about-img">
                      <img
                        className="img-fluid"
                        src="images/about/02about.jpg"
                        alt="02 About"
                      />
                      <div className="experience-about">
                     
                         <div className="fabrication-icon">
                           <FaCog className="rotate-icon" size={50} color="#692a30" />
                         </div>
                        <div className="counter">40</div>
                        <h5>years of experience</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side text content */}
            <div className="col-lg-6 d-flex align-items-center justify-content-between">
              <div className="text-box">
                <div className="sec-title">
                  <h2>Your Trusted Partner in Fabrication Excellence</h2>
                  <h3>Nageshwar Fibre & Fibrication Industries</h3>
                  <p>
                    With years of expertise, Nageshwar Fibre and Fabrication
                    Industries is dedicated to providing top-notch fibre
                    solutions. Whether it’s for industrial, commercial, or
                    residential projects, our commitment to quality
                    craftsmanship ensures you get nothing but the best.
                  </p>
                </div>
                <p>
                  At Nageshwar Fibre and Fabrication Industries, our mission is
                  to create durable, efficient, and customized fabrication
                  solutions that exceed industry standards. Our team is ready to
                  bring your vision to life with our expert designs and
                  unmatched quality.
                </p>
                <ul>
                  <li>Custom Fibre Solutions</li>
                  <li>High-Quality Products</li>
                  <li>Innovative Technology</li>
                  <li>Expert Craftsmanship</li>
                  <li>Customer-Centric Approach</li>
                </ul>
                <div className="any-question text-orange">
                  For inquiries, call us at +91 8793191191, +91 9762431883.
                  We’re here to help!"
                  <a href="tel:+91 879319119"> +91 8793191191 </a> or
                  <a href="tel:+91 9762431883"> +91 9762431883 </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services home-service">
        <div className="container">
          <div className="sec-title">
            <div className="row">
              <div className="col-lg-4">
                <h2>High-Performance, Long-Lasting Products</h2>
                <h3>Nageshwar Fibre & Fibrication Industries</h3>
              </div>
              <div className="col-lg-5 col-md-8 d-flex align-items-center">
                <p>
                  Nageshwar Fibre and Fabrication Industries specializes in
                  crafting high-quality fibre-reinforced products and custom
                  fabrication solutions for various industries.
                </p>
              </div>
              <div className="col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec">
                
                <Link to="/services" className="btn-1 btn-3" > All Services</Link>
                 
               
              </div>
            </div>
          </div>
          <div className="row">
            {servicesData.map((service, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="services-item">
                  <img src={service.img} alt="product image" />
                  <h4>
                    <span></span>
                  </h4>
                  <h2>{service.title}</h2>
                  <p>{service.description}</p>
                  <Link to={service.link} className="more">
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="provide">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="text-box">
                <div className="sec-title">
                  <h3>
                    "Building a Stronger Future with Superior Fabrication"{" "}
                  </h3>
                  <p>
                    At Nageshwar Fibre and Fabrication Industry, we specialize
                    in delivering innovative and high-quality fibre and
                    fabrication products tailored to meet the diverse needs of
                    our clients. With a commitment to excellence, our
                    experienced team combines advanced technology with skilled
                    craftsmanship to create durable solutions for various
                    applications, including industrial, commercial, and
                    residential projects
                  </p>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                    <ul className="provide-features">
                      {features.map((feature, index) => (
                        <li key={index}>
                          <h4>{feature}</h4>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-sm-6 col-md-12 col-lg-6 d-flex align-items-center">
                    <div className="provide-features-box">
                      <i
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "2rem",
                        }}
                      >
                        <GiFactory className=" ms-2" />
                      </i>
                      <h5>We are providing the best products...</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <div className="video-box">
                  <span>Watch Our Presentation!</span>
                  <a
                    href="https://youtu.be/sB6y4RV65kY"
                    className="pulse"
                    data-lity
                  >
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="work py-100-70">
        <div className="container">
          <div className="sec-title">
            <div className="row">
              <div className="col-lg-4">
                <h2>Bringing the art to the cart</h2>
                <h3>Nageshwar Fibre & Fibrication Industries</h3>
              </div>
              <div className="col-lg-5 col-md-8 d-flex align-items-center">
                <p>
                  Nageshwar Fibre and Fabrications, are one of the leading
                  manufacturers of a wide range of superior quality Fibre
                  Reinforced Plastic Products. We are also one of the most
                  sought after suppliers in the same domain of work.
                </p>
              </div>
              <div className="col-lg-3 col-md-4 d-flex align-items-center justify-content-center text-center">
                <Link to="/products" className="btn-1 btn-3">
                  View All Products
                </Link>
              </div>
            </div>
          </div>
          <div className="row  homeimg">
            {projectData.map((project, index) => (
              <WorkItem key={index} {...project}/>
            ))}
          </div>
        </div>
      </section>

      <section className="contact py-100 solution-section">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="sec-title text-center">
                <h3>Looking for Reliable Fibre Solutions?</h3>
                <p>
                  Stop stressing over your project needs. Let Nageshwar Fibre
                  and Fabrication Industries deliver the quality support you
                  deserve!"
                </p>
                <Link className="btn-1" to="/contact">
                  Contact Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="team team-home-2 py-100-70">
        <div className="container">
          <div className="sec-title sec-title-home-2">
            <div className="row container">
              <div className="col-lg-5">
                <h2>Our Products</h2>
                <h3> Premium Quality Products</h3>
              </div>
            </div>
          </div>
          <div className="row container">
            {teamMembers2.map((member, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="team-box">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={member.image}
                      alt={`${member.name} team`}
                    />
                    <div className="img-box-hover">
                      <ul>
                             <Link to="/contact"> <button className="quote-btn">Buy Now</button></Link>
                      </ul>
                    </div>
                  </div>
                  <div className="text-box text-center">
                    <h5>{member.name}</h5>
                    <span>{member.position}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="testimonial mytestimonial-section py-100">
        <div className="container">
          <div className="row container">
            <div className="col-md-10 offset-md-1">
              <Slider {...testimonialSetting}>
                {testimonialsData.map((testimonial, index) => (
                  <div className="item-box" key={index}>
                    <div className="text-box text-center">
                      {testimonial.feedback}
                    </div>
                    <div className="item-name text-center">
                      <i className="flaticon-left-quote"></i>
                      <div className="testimonial-img">
                        <img src={testimonial.img} alt="Testimonial Images" />
                      </div>

                      <h5>{testimonial.name}</h5>
                      <span>{testimonial.designation}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      {/*  ============= product images ===================== */}
      <section className="project-section">
      <div className="project-carousel container-fluid">
        <h2 className="text-uppercase text-brown">Our Projects</h2>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <div key={index} className="project-slide">
              <div className="project-image">
                <img src={project.image} alt={project.name} />
                <div className="project-name">{project.name}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      </section>
     
      {/* ========================== our products section ends ==================== */}
      <BlogSection />
    </>
  );
};

export default Home;
