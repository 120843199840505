import React, { useState, useEffect } from "react";
import './Slider.css'
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";


export default function PlaygroundSlides(){
  const slides = [
    "slider/Slider for product section-01.jpg",
    "slider/Slider for product section-02.jpg",
  ];
   const imgData = [
    {
        img1:'products-img/FRP-Playground-Slides-Manufactures-in-pune.jpg',
        name:'FRP Playground Slides',
        price:' Rs 60,000  / Piece Get Best Price',
        d1:'Material',
        value1:'FRP',
        d2:'Color',
        value2:'Yellow',
        d3:'Slide Type',
        value3:'Straight',
        d4:'Location Type',
        value4:'Outdoor',
        d5:'Rust Resistance',
        value5:'Yes',
        d6:'Country of Origin',
        value6:'Made in India',
        para:'Backed by a rich industrial experience, we have come up with a world class assortment of FRP Playground Slides. Features:DurableGood quality Excellent finish Superb design Full Set',
    },

    {
        img1:'products-img/Water-park-slide-manufacture-in-india.jpg',
        name:'Water Park Slide',
        price:' Rs 10,000  / PieceGet Best Price',
        d1:'Material',
        value1:'FRP(fibre reenforced plastic)',
        d2:'Exit',
        value2:'Pool',
        d3:'Color',
        value3:'Red',
        d4:'Finishing',
        value4:'Color Coated',
        d5:'Corrosion Resistance',
        value5:'Yes',
        d6:'Country of Origin',
        value6:'Made in India',
       para:'Backed by a rich industrial experience, we have come up with a world class assortment of FRP Playground Slides. Features:DurableGood quality Excellent finish Superb design Full SetBacked by a rich industrial experience, we have come up with a world class assortment of FRP Playground Slides. Features:DurableGood quality Excellent finish Superb design Full Set'
    },

    {
        img1:'Product-Images/Outdoor-playground-eqipment-manufactures-pune.jpg',
        name:'Play School Indoor Equipment',
        price:' Rs 16,000  / Set Get Best Price',
        d1:'Minimum Order Quantity',
        value1:'1 Set',
        d2:'Material',
        value2:'FRP',
        d3:' Location Type',
        value3:'All',
        d4:'Usage/Application',
        value4:'School indoor games',
        d5:'Color',
        value5:'All colors',
        d6:'1.5 feet height',
        value6:'Slide 4 feet',
        para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality. ',
        para1:'Fiber play equipment very well finished and tough material',
    },

    {
        img1:'products-img/Paddle-boat-manufacture-in-India.jpg',
        name:'Frp Speed Boats',
        price:'Rs 1.2 Lakh  / Piece Get Best Price',
        d1:'Material',
        value1:'frp',
        d2:' Size/Dimension',
        value2:'12 length',
        d3:'Brand',
        value3:'nff',
        d4:'Color',
        value4:'White',
        d5:'Power Source',
        value5:'engine',
        d6:'Model Name/Number',
        value6:'frp boats',
        d7:'Size/Dimension',
        value7:'Weight-60 kgs',
        para:' Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.',
    },

    {
        img1:'products-img/FRP-Playground-Slides-Manufactures-in-pune.jpg',
        name:'Garden Play Equipment',
        price:'Rs 70,000  / PieceGet Best Price',
        d1:'Minimum Order Quantity',
        value1:'1 Piece',
        d2:'Brand',
        value2:'NFF',
        d3:'Size',
        value3:'20 Feet',
        d4:'Usage/Application',
        value4:'Play Equipment',
        d5:'Material',
        value5:'FRP',
        d6:'Type',
        value6:'Fiber',
        d7:'Capacity',
        value7:'7 seater',
        d8:'Color',
        value8:'Green',
        d9:'Location Type',
        value9:'Pan India',
        d10:'Weight',
        value10:'80 kgs',
        d11:'Slide Material',
        value11:'Fibre',
        d12:'Is It Customized',
        value12:'Non Customized',
        d13:'I Deal In',
        value13:'New Only',
        d14:'Country of Origin',
        value14:'Made in India',
        para:' Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.',
        para1:'5 mm thikness fiber material use and heavy fabrication',
    },
    {
        img1:'products-img/Inflatable-water-slide-for-kids-and-adults-for-sales.jpg',
        name:' Kids Playground Equipment',
        price:'Rs 1.25 Lakh  / Piece Get Best Price',
        d1:'Minimum Order Quantity',
        value1:'1 Set',
        d2:'Material',
        value2:'FRP',
        d3:'Brand',
        value3:'NFF BRAND',
        d4:'Size',
        value4:'25*25*14 Ft',
        d5:'Color',
        value5:'All colors',
        d6:'Usage/Application',
        value6:'Playgrond kids',
        d7:'Capacity',
        value7:'10/15 Chldren',
        para:'NFF Industries,are the best children play park Equipment manufacturers in PUNE. we engaged in offerng   wide range of children1 play park equipment. These are manufactured from high grad quality materiail.very safe for kids. ',
    },

    {
        img1:'products-img/Park-Slides-manufacturerin-pune.jpg',
        name:'Frp Curve Slide',
        price:'Rs 90,000  / Piece Get Best Price',
        d1:'Minimum Order Quantity',
        value1:'1 Set',
        d2:'Material',
        value2:'FRP',
        d3:'Slide Type',
        value3:'Curve Slide',
        d4:'Number Of Slides',
        value4:'2 Slides',
        d5:'Exit',
        value5:'Pool',
        d6:'Color',
        value6:'All Colour Available',
        d7:'No Of Riders',
        value7:'2',
        d8:'Features',
        value8:'Durable ,Crack Proof',
        d9:'Usage/Application',
        value9:'Play Ground, Park',
        d10:'Location Type',
        value10:'Outdoor',
        d11:'Country of Origin',
        value11:'Made in India',
        para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.',
    },
    
  

    {
      img1:'Product-Images/FRP Airplane Slide.jpg',
      name:'FRP Airplane Slide',
      price:'Rs 3.5 Lakh  / Piece',
      d1:'Minimum Order Quantity',
      value1:'1 Piece',
      d2:'Material',
      value2:'FRP',
      d3:'Brand',
      value3:'Kidsplay',
      d4:'Color',
      value4:'All Color',
      d5:'Location',
      value5:'	Outdoor',
      d6:'Country of Origin',
      value6:'Made in India',
      d7:'Slide Type',
      value7:'Straight',
      para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.',
      para1:'Frp airoplane capacity upto 30 kids, Upto 200 kgs capacity'
    },
    {
      img1:'Product-Images/Playground-slides-manufactures-in-pune.jpg',
      name:'Playground Slides',
      price:'Rs 90,000  / Piece',
      d1:'Minimum Order Quantity',
      value1:'1 Piece',
      d2:'Material',
      value2:'Fibreglass',
      d3:'Brand',
      value3:'Kidsplay',
      d4:'Color',
      value4:'Red',
      d5:'Slide Type',
      value5:'Roller',
      d6:'Location Type',
      value6:'Outdoor',
      d7:'Country of Origin',
      value7:'Made in Indiat',
      para:'With honest intentions to improve the standing we have earned in this arena, we are offering a distinct collection of Playground Slides. Features:High quality Fine finish Long lasting Seamless design',
    },
  
   ]
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to change slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Automatically move the slide
  useEffect(() => {
    const sliderInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(sliderInterval); // Clean up on component unmount
  }, [currentSlide, 3000]);
    return(
        <>
        <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Plastic dustbin manufacturers in Pune, Portable Cabin Manufacturers in Pune, Fiber Door Manufacturers in Pune,FRP Planters Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
         <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {index === currentSlide && (
              <img src={slide} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>


      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
        </>
    )
}