import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function MobileToilet(){
    const imgData=[
        {
            "img1": "Product-Images/FRP-MobileToilet-Manufactures-in-pune.jpg",
            "name": "FRP Mobile Toilets",
            "price": "Rs 18,000 / Piece",
            "d1": "Design",
            "value1": "Modern",
            "d2": "Color",
            "value2": "Blue",
            "d3": "Size",
            "value3": "3x3x6.5 Feet",
            "d4": "Shape",
            "value4": "Rectangular",
            "d5": "Built Type",
            "value5": "Modular",
            "d6": "Material",
            "value6": "FRP",
            "para": "Nageshwar Fibre & Fabrication Industries offers high-quality FRP Mobile Toilets designed for convenience and comfort. With a modern design and a striking blue color, these toilets are both functional and aesthetically pleasing. Measuring 3x3x6.5 feet and constructed in a rectangular shape, they provide ample space for use. Built using durable FRP material, our mobile toilets feature a heavy-duty structure with a stylish wooden finish, ensuring longevity and reliability for outdoor events, construction sites, and other temporary facilities."
        },
        {
            "img1": "Product-Images/Mobile Toilet Van.jpg",
            "name": "Mobile Toilet Van",
            "price": "Rs 3.75 Lakh / Piece",
            "d1": "Country of Origin",
            "value1": "Made in India",
            "d2": "Color",
            "value2": "All colors available",
            "d3": "Built Type",
            "value3": "Prefab",
            "d4": "Usage/Application",
            "value4": "Mobile Toilet Van",
            "d5": "Material",
            "value5": "FRP",
            "d6": "Pipe Material",
            "value6": "Paras system",
            "d7": "Tank Capacity",
            "value7": "Water tank - 1000 litres, Wastage tank - 2000 litres",
            "d8": "Wall Height",
            "value8": "8 feet",
            "d9": "Container Length",
            "value9": "18 feet",
            "d10": "Shape",
            "value10": "As per requirement",
            "d11": "No. Of Compartments",
            "value11": "10",
            "d12": "Brand",
            "value12": "NFF",
            "d13": "Size",
            "value13": "3*3, 18 feet",
            "para": "Nageshwar Fibre & Fabrication Industries is a leading manufacturer and supplier of Mobile Toilet Vans. Our Mobile Toilet Van is constructed using advanced technology and high-grade raw materials, ensuring durability and efficiency. With a customizable shape and available in various colors, these vans are built to meet diverse needs. Featuring a water tank capacity of 1000 litres and a waste tank capacity of 2000 litres, along with a wall height of 8 feet and a container length of 18 feet, our Mobile Toilet Van offers ample space and comfort for users. Each unit includes 10 compartments, making it ideal for large events and gatherings."
        },

        {
            "img1": "Product-Images/FRP-Portable-toilet-and-urinals-manufactures-in-pune.jpg",
            "name": "Fiber Portable Toilets",
            "price": "Rs 15,000 / Piece",
            "d1": "Country of Origin",
            "value1": "Made in India",
            "d2": "Color",
            "value2": "Any color available",
            "d3": "Usage/Application",
            "value3": "Fibre Toilets",
            "d4": "Is It Portable",
            "value4": "Yes",
            "d5": "Material",
            "value5": "FRP",
            "d6": "Shape",
            "value6": "Rectangular",
            "d7": "Built Type",
            "value7": "Modular",
            "para": "Nageshwar Fibre & Fabrication Industries offers high-quality Fiber Portable Toilets designed for versatility and convenience. Constructed from durable FRP material, these toilets are lightweight and portable, making them perfect for events, construction sites, and outdoor activities. Available in any color, our Fiber Portable Toilets come in a rectangular shape and modular built type, ensuring ease of setup and maintenance. Developed using top-notch materials and advanced techniques, we guarantee timely delivery and superior quality for all our products."
        }
    
    ]
    return(
        <>
<Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Plastic dustbin manufacturers in Pune, Portable Cabin Manufacturers in Pune, Fiber Door Manufacturers in Pune,FRP Planters Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
   <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Frp Mobile Toilet</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>frp Mobile Toilet Manufacturer in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      
     {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
           frp Mobile Toilet Manufacturer in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
         <p>Nageshwar Fibre & Fabrication Industries is a leading manufacturer of high-quality FRP Mobile Toilets in Pune. Our mobile toilets are designed with modern technology and made from premium-grade fiberglass reinforced plastic (FRP), ensuring durability and strength for various applications.</p>
        </div>
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to='/contact'>Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


        {/*  ================= Contact Details ==================== */}
        <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div> 
        </>
    )
}