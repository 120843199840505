import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function OutdoorPlaygroundEquipmentInPune(){
    const imgData = [
        {
          img1: "Product-Images/2-Seater FRP Swan Paddle Boat.jpg",
          name: "2 Seater FRP Swan Paddle Boat",
          price: "Rs 60,000 / Piece",
          d1: "Power Source",
          value1: "Manual",
          d2: "Length",
          value2: "8.5 ft",
          d3: "Material",
          value3: "FRP",
          d4: "Width",
          value4: "5.5 ft",
          d5: "Height",
          value5: "4 ft",
          d6: "Color",
          value6: "Multicolor",
          d7: "Size/Dimension",
          value7: "8.5 x 5.5 x 4 ft",
          d8: "Seating Capacity",
          value8: "2 Seater",
          d9: "Capacity",
          value9: "2",
          d10: "Country of Origin",
          value10: "Made in India",
          para: "Nageswar Fiber & Fabrication Industries presents the 2 Seater FRP Swan Paddle Boat, a perfect addition for leisurely rides on water. Crafted from high-quality FRP, this paddle boat is designed for durability and aesthetic appeal, ensuring a delightful experience for users. Ideal for parks, resorts, and recreational areas.",
        },
        {
          img1: "Product-Images/FRP Wave Slide.jpg",
          name: "FRP Wave Slide 6 Ft Height",
          price: "Rs 45,000 / Set",
          d1: "Brand",
          value1: "NFF INDUSTRIES",
          d2: "Child Age Group",
          value2: "3-12 years",
          d3: "Country of Origin",
          value3: "Made in India",
          para: "The FRP Wave Slide, standing 6 feet tall, is an essential addition to any water park, hotel, or resort. Designed for children aged 3-12, it combines fun and safety, manufactured according to international standards using high-grade materials. Perfect for enhancing the excitement in water play areas, this slide is available in various designs and colors to suit your needs.",
        },
        {
          img1: "Product-Images/MultiActiviity-play-station-manufactures-in-pune.jpg",
          name: "FRP Multi Play Station",
          price: "Rs 40 Lakh / Piece",
          d1: "Brand",
          value1: "NFF INDUSTRIES",
          d2: "Country of Origin",
          value2: "Made in India",
          d3: "Material",
          value3: "Fiberglass",
          para: "The FRP Multi Play Station is a versatile addition to any water park, hotel, or resort. Manufactured from high-quality fiberglass and adhering to international standards, this play station offers a variety of engaging activities for children, ensuring a safe and enjoyable experience. Available in various designs, shapes, and colors, it’s perfect for enhancing play areas and attracting visitors.",
        },
        {
          img1: "Product-Images/FRP Playground swing.jpg",
          name: "FRP Playground Swings",
          price: "Rs 41,000 / Set",
          d1: "Seating Capacity",
          value1: "2 Seater",
          d2: "Swing Type",
          value2: "Outdoor",
          d3: "Material",
          value3: "FRP",
          d4: "Color",
          value4: "All Colour",
          d5: "Usage/Application",
          value5: "Outdoor Playground",
          d6: "Seat Type",
          value6: "FRP",
          para: "The FRP Playground Swings are designed for outdoor playgrounds, accommodating two users at a time. Crafted from high-quality FRP material with a wooden finish, these swings offer both durability and aesthetic appeal. They are manufactured under strict quality standards, ensuring a safe and enjoyable experience for children while enhancing any playground setup.",
        },
      ];
    return(
        <>
<Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Plastic dustbin manufacturers in Pune, Portable Cabin Manufacturers in Pune, Fiber Door Manufacturers in Pune,FRP Planters Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
   <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Outdoor Play Equipments </h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Outdoor Playground Equipment Manufacturers in India</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
           {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}
        <div className="container product-section">
          <h1>
          Outdoor Playground Equipment Manufacturers in India – Nageshwar Fibre And
            Fabrication Industries
          </h1>
         <p>Nageshwar Fibre and Fabrication Industries is a trusted name among outdoor playground equipment manufacturers in India. Known for producing a wide range of high-quality, durable, and safe playground equipment, the company has established itself as a leader in the industry. Their equipment is designed to foster physical activity, creativity, and social interaction among children, making them a preferred choice for schools, parks, and residential complexes.</p>
        </div>
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
             {/*  ================= Contact Details ==================== */}
             <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>

        </>
    )
}