import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";



export default function FiberPillars(){
    const imgData = [
        {
            img1: 'Product-Images/Decoration Fiber Pillars.jpg',
            name: 'Decoration Fiber Pillars',
            price: 'Rs 1,000 / sq ft Get Best Price',
            d1: 'Country of Origin',
            value1: 'Made in India',
            d2: 'Material',
            value2: 'Polyresin',
            d3: 'Brand',
            value3: 'NFF Industries',
            d4: 'Color',
            value4: 'White',
            d5: 'Shape',
            value5: 'Circular, Square',
            d6: 'Usage/Application',
            value6: 'Decoration',
            para: 'Nageshwar Fibre and Fabrication Industries offers high-quality Decoration Fiber Pillars in a variety of designs, sizes, and specifications. Made from durable polyresin material, these pillars are perfect for enhancing the aesthetic appeal of any space, with options available in both circular and square shapes.'
        },
        {
            img1: 'Product-Images/Fiber-pillar-manufactures-in-pune.jpg',
            name: 'Fiberglass Temple Pillar',
            price: 'Rs 1,000 / sq ft Get Best Price',
            d1: 'Type',
            value1: 'Temple Pillar',
            d2: 'Shape',
            value2: 'Rectangular',
            d3: 'Material',
            value3: 'Resin',
            d4: 'Technique',
            value4: 'Modern',
            d5: 'Color',
            value5: 'White',
            d6: 'Usage/Application',
            value6: 'Decoration',
            para: 'Nageshwar Fibre and Fabrication Industries offers Fiberglass Temple Pillars, crafted with high-grade resin materials and designed for decorative purposes. These temple pillars come in rectangular shapes and modern techniques, ensuring a blend of tradition and modernity in temples or any architectural settings. Available in various designs and sizes, they enhance the aesthetic value of any space.'
        },
        {
            img1: 'Product-Images/FRP Decorative Pillar.jpg',
            name: 'FRP Decorative Pillar',
            price: 'Rs 10,000 / Piece Get Best Price',
            d1: 'Country of Origin',
            value1: 'Made in India',
            d2: 'Diameter',
            value2: '10 inch',
            d3: 'Shape',
            value3: 'Circular',
            d4: 'Design',
            value4: 'Customized',
            d5: 'Material',
            value5: 'Iron',
            d6: 'Brand',
            value6: 'Zeenat',
            d7: 'Color',
            value7: 'Golden',
            d8: 'Dimension',
            value8: '5 ft',
            d9: 'Usage/Application',
            value9: 'Decoration',
            para: 'Nageshwar Fibre and Fabrication Industries proudly presents the FRP Decorative Pillar, crafted with a 10-inch diameter and a height of 5 feet. Made from high-quality iron and finished in an elegant golden color, these pillars are customizable to suit various decorative needs. Ideal for enhancing the aesthetic appeal of any space, this product has received widespread appreciation from clients for its quality and design.'
        }
                
        
    ]
    return(
        <>
         <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
         Contact: +91 8793191191, +91 9762431883|  Nageshwar Fibre And Fabrication Industries|Umbrella Manufacturers in Pune
          | Fiberglass Swimming Pool Contractors in Pune | Tube Slide Water Park Contractor in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Umbrella Manufacturers in Pune, Fiberglass Swimming Pool Contractors in Pune ,Tube Slide Water Park Contractor in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
         <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Fiber Pillars </h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>  Fiber Pillar Manufacturers in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
          Fiber Pillar Manufacturers in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
          <p>Nageshwar Fibre and Fabrication Industries is a prominent fiber pillar manufacturer in Pune, specializing in a diverse range of high-quality fiber pillars designed for both decorative and functional purposes. With a commitment to excellence and customer satisfaction, they have established themselves as a trusted name in the industry.</p>
        </div>

        {imgData.map((e) => {
          return (  
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


       {/*  ================= Contact Details ==================== */}
       <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>


        </>
    )
}