import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import { useState, React } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { FaUser, FaCogs } from "react-icons/fa";
import Slider from "react-slick";
import { LiaStackExchange } from "react-icons/lia";
import "./App.css";
import { Helmet } from "react-helmet";

export default function About() {
  const styles = {
    container: {
      // borderRadius: "8px",
      // padding: "20px",
      margin: "20px auto",
      maxWidth: "900px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      fontFamily: "Arial, sans-serif",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#fff",
      textAlign: "center",
      marginBottom: "15px",
    },
    description: {
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#fff",
      marginBottom: "15px",
    },
    list: {
      listStyleType: "circle",
      paddingLeft: "20px",
      marginBottom: "15px",
    },
    listItem: {
      fontSize: "16px",
      color: "#fff",
      marginBottom: "5px",
    },
    featuresList: {
      listStyleType: "square",
      paddingLeft: "20px",
      marginBottom: "15px",
    },
    featuresItem: {
      fontSize: "16px",
      color: "#fff",
      marginBottom: "5px",
    },
  };

  const products = [
    {
      title: "Fibre Reinforced Plastic Products",
      imgSrc: "images/shop/01_shop.jpg",
    },
    { title: "2-Seater paddle boat", imgSrc: "gallery/2-seater-boat.jpg" },
    { title: "bustbin", imgSrc: "gallery/dustbin.jpg" },
    { title: "FRP Umbrella", imgSrc: "galleryFibre umbrella.jpg" },
    { title: "FRP Elephant", imgSrc: "gallery/Elephant statue.jpg" },
    { title: "FRP Garden Swing", imgSrc: "gallery/garden swing.jpg" },
    { title: "FRP Murals", imgSrc: "path/to/image7.jpg" },
    { title: "FRP Security Cabin", imgSrc: "path/to/image8.jpg" },
    { title: "Fibre Products", imgSrc: "path/to/image9.jpg" },
    { title: "FRP and MS Bench", imgSrc: "path/to/image10.jpg" },
    { title: "FRP Playing Equipment", imgSrc: "path/to/image11.jpg" },
    { title: "Industrial FRP Products", imgSrc: "path/to/image12.jpg" },
    { title: "Play Equipments", imgSrc: "path/to/image13.jpg" },
  ];

  const testimonialsData = [
    {
      name: "Sneha Patil, Pune",
      img: "images/testimonial/testL1.png",
      feedback:
        "Working with Nageshwar Fibre & Fabrication Industries has been a transformative experience for us. Their attention to detail and commitment to quality have resulted in exceptional products that consistently meet our needs. Their team is knowledgeable, responsive, and dedicated to ensuring our satisfaction. Highly recommended!",
    },
    {
      name: "Rahul Mehta",
      img: "images/testimonial/testG1.png",
      feedback:
        "Nageshwar Fibre & Fabrication Industries stands out in the industry for its customer-centric approach. Every project we’ve undertaken with them has been a breeze due to their proactive communication and timely delivery. Their products are of top-notch quality, and we couldn't be happier with our partnership.",
    },
    {
      name: "Vikram Desai",
      img: "images/testimonial/testG2.png",
      feedback:
        "Partnering with Nageshwar Fibre & Fabrication Industries has been a game-changer for our business. Their products reflect superior craftsmanship, and the team goes above and beyond to tailor solutions that fit our specific requirements. Their dedication to excellence is evident in every interaction.",
    },
    {
      name: "Sunita Joshi",
      img: "images/testimonial/testL2.png",
      feedback:
        "We have been sourcing our fibre and fabrication needs from Nageshwar for over three years, and their consistency in delivering quality products is unparalleled. Their ethical practices and commitment to sustainability resonate with our values, making them our preferred supplier. I highly endorse their services!",
    },
    {
      name: "Priya Kapoor",
      img: "images/testimonial/testL3.png",
      feedback:
        "Nageshwar Fibre & Fabrication Industries has exceeded our expectations in every project. Their innovative approach to fabrication has helped us create products that not only meet industry standards but also contribute to a greener future. The team’s collaborative spirit makes them a pleasure to work with!",
    },

    {
      name: "Rajesh Kulkarni",
      img: "images/testimonial/testG3.png",
      feedback:
        "As an engineer, I value precision and quality, and Nageshwar Fibre & Fabrication Industries delivers on both fronts. Their products are robust and reliable, perfect for our construction projects. The team is always available to assist and provide solutions, making them an invaluable partner for us.",
    },
    {
      name: " Anjali Verma",
      img: "images/testimonial/testL4.png",
      feedback:
        '"Nageshwar Fibre & Fabrication Industries is our go-to partner for all our fibre needs. Their professionalism and dedication to customer satisfaction are commendable. The quality of their products has allowed us to enhance our offerings significantly. I cannot recommend them enough!"',
    },
  ];

  const testimonialSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const services = [
    {
      icon: <FaUser />,
      title: "Working Ability",
      description:
        "Lorem Ipsum is simply text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy.",
    },
    {
      icon: <FaCogs />,
      title: "Machine Power",
      description:
        "Lorem Ipsum is simply text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy.",
    },
  ];

  const servicesData = [
    {
      title: "Quality Assurance",
      description:
        "Being a quality centric company, we strive to constantly deliver optimum quality product to our clients. To assure high quality standards in our products, we adhere to a stringent quality policy in our company. Some of the highlighting points in our quality policy are as follows: Sourcing the raw material from reputed vendors only Assisting the entire production process Subjecting the products to various quality checks Re-checking the final products Employing experienced quality auditors Moreover, our quality auditors follow various stringent quality parameters to check the quality of the products. Owing to their incessant efforts to come up with superior quality products, we are able to consistently deliver high end products to our clients.",
    
    },
    {
      title: "Infrastructure",
      description:
        "Our sophisticated infrastructure is enabled us to successfully climb the ladder of success. It has all requisite facilities like advanced machinery and equipment to come up with prompt production of products.  Most of the machines are imported from international market and can be upgraded frequently. To make full utilization of the resources, we have employed a team of highly experienced experts. Our infrastructure consists of a manufacturing unit, quality testing unit, designing unit, R&D unit and a warehouse. Owing to these high-tech facilities of our infrastructure, we smoothly carry out entire production process and meet the bulk requirements of clients. Further, we have uninterrupted power supply in our unit, which helps us in undertaking different activities without any hindrances.",
     
    },
    {
      title: "Research and Development",
      description:
        "We have a well developed R&D department, which carries out all the result oriented researches about the domain. Our R&D department conducts various researches to come up with newer innovations and ideas that might increase the production capacity without even hampering the quality of the products. It is backed by a team of experts with years of experience in the domain are working with us. They also conduct market research to avail us with the latest demand in the market and also of the customers. Moreover, the department also strives hard to introduce newer technology in our production unit and makes a thorough study about the existing machines in order to augment their performance.",
     
    },
    {
      title: "Warehouse",
      description:
        "Our customers can get hold of our products for the convenience, as we have bulk stock in our warehouse. For the safe storage of all our versified products, we have arranged a spacious warehouse. Our warehouse is time to time cleaned up to ensure the damage free storage of our products. The entire warehouse is systemically segmented into different sections so as to categorically store different items. It is looked after by a team of efficient warehouse personnel who are also assigned to keep a track of the products stored and delivered.",
      
    },
    {
      title: "Our Team",
      description:
        "We have a team of dexterous and qualified professionals, who are assisting us in expanding our business activities day by day. All our professionals are possesses wide industry in their respective work. Their commitment, dedication and hard work have enabled us to achieve milestones. Our team is highly motivated by team spirit and they work hard to achieve the requirements of the clients. To bring more refinement and perfection in our professionals, we conduct various raining sessions for them. Our team of professionals comprises:",
      list1: "Designers",
      list2: "Techno-carts",
      list3: "Quality auditors",
      list4: "R&D staff",
      list5: "Sales and marketing",
      list6: "Skilled and non skilled staff",
    },
    {
      title: "Client Satisfaction",
      description:
        "We count our clients as our assets, and hence we remain throughout concerned about delighting them in all the possible ways. Toe the line with the ethos, we offer them excellent quality products in market leading prices. Moreover, we always remain ethical and transparent in our business practices. We keenly listen to the feed backs of our customers, to understand their needs in a closer manner. Further, we remain throughout accessible to assist them in any way possible.",
      
    },
  ];

  const teamMembers2 = [
    {
      name: "Animal and Fibre statue manufacturing ",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "products-img/Animal-and-Fiber-statue-mnaufacturing-in-pune.jpg",
      socials: [
        "fab fa-facebook-f",
      ],
    },
    {
      name: "Playground Slides",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "Product-Images/Playground-slides-manufactures-in-pune.jpg",
      socials: [
      
        "fab fa-twitter",
       
      ],
    },
    {
      name: "Swimmingg Pool",
      position: "Nageshwar Fibre & Fibrication Industries",
      image: "products-img/Park-Slides-manufacturerin-pune.jpg",
      socials: [
        "fab fa-facebook-f",
      
      ],
    },
  ];

  const services1 = [
    {
      icon: "flaticon-gears",
      title: "Great Support",
      description:
        "Lorem Ipsum simply dummy text of the printing typesetting industry.",
    },
    {
      icon: "flaticon-forklift",
      title: "Unique Technology",
      description:
        "Lorem Ipsum simply dummy text of the printing typesetting industry.",
    },
    {
      icon: "flaticon-walkie-talkie",
      title: "Smart Work",
      description:
        "Lorem Ipsum simply dummy text of the printing typesetting industry.",
    },
    {
      icon: "flaticon-statistics",
      title: "Quality Materials",
      description:
        "Lorem Ipsum simply dummy text of the printing typesetting industry.",
    },
  ];
  const sponsors = [
    { id: 1, src: "images/sponsors/01_sponsors.png", alt: "01 Sponsors" },
    { id: 2, src: "images/sponsors/02_sponsors.png", alt: "02 Sponsors" },
    { id: 3, src: "images/sponsors/03_sponsors.png", alt: "03 Sponsors" },
    { id: 4, src: "images/sponsors/04_sponsors.png", alt: "04 Sponsors" },
  ];

  const timer = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const testimonials = [
    {
      id: 1,
      text: "Highly recommended & a great experience. The process was simple and easy to understand. Trading was straight forward, the entire process was super smooth!",
      name: "Anwar Ramadan",
      agency: "AR-Coder Agency",
      imgSrc: "images/testimonial/01_testimonial.jpg",
    },
    {
      id: 2,
      text: "Highly recommended & a great experience. The process was simple and easy to understand. Trading was straight forward, the entire process was super smooth!",
      name: "Osama Bakri",
      agency: "NiftyTheme Agency",
      imgSrc: "images/testimonial/02_testimonial.jpg",
    },
    {
      id: 3,
      text: "Highly recommended & a great experience. The process was simple and easy to understand. Trading was straight forward, the entire process was super smooth!",
      name: "Mohamed Said",
      agency: "AR-Coder Agency",
      imgSrc: "images/testimonial/03_testimonial.jpg",
    },
  ];

  const testimonialTimer = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const blogPosts = [
    {
      id: 1,
      title: "Importance of specialized focus in portfolio oil & gas",
      date: "Jan 20, 2021",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua...",
      imgSrc: "images/blog/01_blog.jpg",
      category: ["Oil & Gas", "Insights"],
   
    },
    {
      id: 2,
      title: "How to build a powerful building Construction plan",
      date: "Jan 25, 2021",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua...",
      imgSrc: "images/blog/02_blog.jpg",
      category: ["Engineering", "Construction"],
      
    },
    {
      id: 3,
      title: "We are designing industry materials of innovation",
      date: "Jan 30, 2021",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua...",
      imgSrc: "images/blog/03_blog.jpg",
      category: ["Industry", "Engineering"],
      
    },
  ];

  const images = [
       
     "gallery/2-seater-boat.jpg",
    "gallery/dustbin.jpg",
   "gallery/Fibre umbrella.jpg" ,
  "gallery/Elephant statue.jpg",
  "gallery/garden swing.jpg" ,
  "gallery/industrial shed.jpg",
  "gallery/playground equipment.jpg",
  "gallery/water park slides.jpg",
"gallery/shivaji maharaj statue.jpg",
"gallery/kolambus ride.jpg",
"gallery/Trampoline.jpg",
"gallery/wedding stage manufacture in pune.jpg",
"gallery/tube slider.jpg"
  ];

  return (
    <>
      <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar Fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.8",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries |Playground Slide Manufacturers in Pune | Water Park
          Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Playground Slide Manufacturers in Pune, Water Park Slide Manufacturers in India ,Park Slides Manufacturer in Pune"
        />
        <meta
          name="keywords"
          content="Playground Slide Manufacturers in Pune, Water Park Slide Manufacturers in India , Park Slides Manufacturer in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <section
        className="breadcrumb-header breadcrumb-header-2  gallery-image"
        id="page"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>About Us</h1>
                <ul>
                  {/* Use Link component if routing is set up */}
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ================================ */}
      <section className="about-us about-us-home-2 about-page-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                className=""
                src="gallery/nageshwar fibre.jpg"
                alt="About Us Home 2"
              />
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="">
                <h1 className="text-brown">Welcome to our Nageshwar Fibre & Fibrication Industries</h1>
                <h2>
                  "Fibre Products: Shaping the Future with Innovation and
                  Durability"
                </h2>
                <p>
                  In building our corporate edifice, our competent professionals
                  have made a major contribution. Further, because of our
                  sophisticated infrastructure, quality checking techniques and
                  other arrangements, we have been able to add more value to our
                  products. Having a good financial position, we have become a
                  reckoned name in the industry. By supplying optimum quality
                  products all across the globe, we have attained wide industry
                  recognition.
                </p>
                <p>
                  We have developed a modular infrastructural unit that is built
                  over a huge area. We have divided our infrastructure into
                  varied units such as production, quality testing, and
                  warehousing & packaging departments to maintain efficient work
                  flow. The production unit of our firm is installed with the
                  upgraded machinery that helps us in catering to the bulk
                  orders of our patrons. In order to retain the quality in the
                  products, we have developed a testing unit that is managed by
                  our skilled professionals. In order to attain utmost level of
                  customer satisfaction, our products are developed in
                  accordance with the specifications provided by the customers
                  with the assured quality. Under the guidance of our mentor,<span className="text-orange"> 
                    <strong> Mr. Nikhil Wavhal (Partner)</strong></span>
                  , we have been
                  able to gain the trust of our customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======================= Factsheet  Section start ========================== */}
      <section className="services " id="fact-section">
        <div class="container">
          <h2 className="text-center text-orange fs-1">Factsheet</h2>
          <h4 className="text-brown fs-3">Basic Information</h4>
          <div className="mytable">
            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "10px" }} className="text-orange fs-5">
                    Details
                  </th>
                  <th style={{ border: "1px solid black", padding: "10px" }}  className="text-orange fs-5">
                    Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Nature of Business
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Manufacturer
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Company CEO
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Nikhil Wavhal
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Total Number of Employees
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    11 to 25 People
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Year of Establishment
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    1991
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Ownership Type
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Partnership
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Annual Turnover
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Rs. 50 Lakh – 1 Crore
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mytable">
            <h4 className='text-brown fs-3'>Company USP</h4>

            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "10px" }} className="text-orange fs-4">
                    Details
                  </th>
                  <th style={{ border: "1px solid black", padding: "10px" }} className="text-orange fs-4">
                    Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Quality Measures / Testing Facilities
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Yes
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mytable">
            <h4 className='text-brown fs-3'>Statutory Profile</h4>
            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "10px" }}>
                    Details
                  </th>
                  <th style={{ border: "1px solid black", padding: "10px" }}>
                    Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    PAN No.
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    AANFN0956H
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mytable">
            <h4 className='text-brown fs-3'>Packaging/Payment and Shipment Details</h4>
            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "10px" }} className="text-orange fs-4">
                    Details
                  </th>
                  <th style={{ border: "1px solid black", padding: "10px" }} className="text-orange fs-4">
                    Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Customized Packaging
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Yes
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Payment Mode
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <ul style={{ listStyleType: "none", padding: "0" }}>
                      <li>Cash</li>
                      <li>Credit Card</li>
                      <li>Cheque</li>
                      <li>DD</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    Shipment Mode
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    By Road
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* =========================== section end= ================ */}

      {/* ======================== */}

      <section className="services services-home-2 py-100-70">
        <div className="overlay"></div>
        <div className="container">
          <div className="sec-title sec-title-home-2">
            <div className="row">
              <div className="col-lg-5">
                <h2>Services We Offer</h2>
                <h3>Our Product Range</h3>
              </div>
              <div className="col-lg-5 d-flex align-items-center">
                <p>
                  Driven by a customer-oriented approach, we are highly
                  committed to providing an unmatched quality range of products
                  to our esteemed customers. We are further dedicated to
                  following ethical business practices that help us in attaining
                  the highest satisfaction and confidence of our patrons.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {servicesData.map((service, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="services-item">
                  <span></span>
                  {/* <i className={service.icon}></i> */}
                  <h4>{service.title}</h4>
                  <p>{service.description}</p>
                  <ul>
                    <li>{service.list1}</li>
                    <li>{service.list2}</li>
                    <li>{service.list3}</li>
                    <li>{service.list4}</li>
                    <li>{service.list5}</li>
                    <li>{service.list6}</li>
                  </ul>
                  <Link to="/services" className="more">
                    read more
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="team team-home-2 py-100-70">
        <div className="container">
          <div className="sec-title sec-title-home-2">
            <div className="row">
              <div className="col-lg-5">
                <h2>Our Products</h2>
                <h3> Premium Quality Products</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {teamMembers2.map((member, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="team-box">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={member.image}
                      alt={`${member.name} team`}
                    />
                    <div className="img-box-hover">
                    <ul>
                             <Link to="/contact"> <button className="quote-btn">Buy Now</button></Link>
                      </ul>
                    </div>
                  </div>
                  <div className="text-box text-center">
                    <h5>{member.name}</h5>
                    <span>{member.position}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="provide-2 provide-2-home-2 mysection">
        {/* <div className="bg-section">
          <div className="overlay"></div>
        </div> */}
        <div className="container">
          <div className="sec-title sec-title-home-2">
            <div className="row">
              <div style={styles.container}>
                <h2 style={styles.title}>Why Us?</h2>
                <p style={styles.description}>
                  By offering optimum quality products to our clients, we have
                  garnered a good name in the market. A few more highlighting
                  points about us are as follows:
                </p>
                <ul style={styles.list}>
                  <li style={styles.listItem}>
                    A team of competent professionals
                  </li>
                  <li style={styles.listItem}>Client-centric approach</li>
                  <li style={styles.listItem}>Competitive prices</li>
                  <li style={styles.listItem}>
                    Timely delivery of consignments
                  </li>
                </ul>
                <p style={styles.description}>
                  Driven by a customer-oriented approach, we are highly
                  committed to providing an unmatched quality range of products
                  to our esteemed customers. We are further dedicated to
                  following ethical business practices that help us in attaining
                  the highest satisfaction and confidence of our patrons. Owing
                  to the highest quality of the products we provide, we have
                  attained a noticeable place in the industry. Some of the other
                  features that enable us to gather a large client base across
                  the nation are mentioned below:
                </p>
                <ul style={styles.featuresList}>
                  <li style={styles.featuresItem}>Advanced infrastructure</li>
                  <li style={styles.featuresItem}>Dedicated professionals</li>
                  <li style={styles.featuresItem}>Quality-approved products</li>
                  <li style={styles.featuresItem}>Industry-leading prices</li>
                  <li style={styles.featuresItem}>
                    Ethical business practices
                  </li>
                  <li style={styles.featuresItem}>
                    Extensive distribution network
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======================= testimonial section =================== */}

      <section className="testimonial py-100 about-testimonial">
        <div className="container">
          <div className="row container">
            <div className="col-md-10 offset-md-1">
              <Slider {...testimonialSetting}>
                {testimonialsData.map((testimonial, index) => (
                  <div className="item-box" key={index}>
                    <div className="text-box text-center">
                      {testimonial.feedback}
                    </div>
                    <div className="item-name text-center">
                      <i className="flaticon-left-quote"></i>
                      <div className="testimonial-img">
                        <img src={testimonial.img} alt="Testimonial Images" />
                      </div>

                      <h5>{testimonial.name}</h5>
                      <span>{testimonial.designation}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

   

      {/* ===================  blog section start ================= */}

      <div className="carousel-container">
      <div className="carousel-slide">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`carousel-img-${index}`} className="carousel-image" />
        ))}
        {/* Duplicating images for smooth infinite loop */}
        {images.map((image, index) => (
          <img key={index + images.length} src={image} alt={`carousel-img-${index + images.length}`} className="carousel-image" />
        ))}
      </div>
    </div>
    </>
  );
}
