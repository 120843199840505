import React, { useState, useEffect } from "react";
import './Slider.css'
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
export default function FiberglassPool() {
  const slides = [
    "slider/Slider for product section-01.jpg",
    "slider/Slider for product section-02.jpg",
  ];
   const imgData = [
    {
        img1: 'Product-Images/Fiberglass-swimming-pool-manufactures-near-me.jpg', 
        name: 'Fiberglass Swimming Pools',
        price: 'Rs 650 / sq ft Get Best Price',
        d1: 'Height',
        value1: '5 Ft',
        d2: 'Usage/Application',
        value2: 'Amusement Park',
        d3: 'Material',
        value3: 'FRP',
        d4: 'Pool Type',
        value4: 'Outdoor',
        d5: 'Color',
        value5: 'Blue',
        d6: 'Capacity',
        value6: 'Approx 16000 Ltr',
        d7: 'Filter Pump',
        value7: 'Included',
        d8: 'Design Type',
        value8: 'Rectangular',
        d9: 'Features',
        value9: 'ISO NPG Gel Coat Finish',
        d10: 'Weight',
        value10: '1000 kg',
        d11: 'Installation',
        value11: 'Included',
        d12: 'Power',
        value12: 'Single Phase',
        d13: 'Total Jet',
        value13: 'Turbo Jet',
        d14: 'Model Name/Number',
        value14: '816',
        d15: 'Voltage',
        value15: '230 V',
        d16: 'Thickness',
        value16: '12 mm',
        d17: 'Warranty',
        value17: '10 Year (Self Life 25 Years)',
        d18: 'Type',
        value18: 'Fiberglass Swimming',
        d19: 'Country of Origin',
        value19: 'Made in India',
        para: 'Nageshwar Fibre & Fabrication Industries is known for manufacturing a qualitative range of products, including Fiberglass Swimming Pools. This pool features a 12 mm FRP sheet, comes with a turbo jet system, and has a durable ISO NPG gel coat finish, ensuring high quality and longevity.'
    },
    {
        img1: 'Product-Images/FRP-Swimming-pool-manufactures-in-india.jpg', 
        name: 'Portable Swimming Pool',
        price: 'Rs 600 / sq ft Get Best Price',
        d1: 'Dimension',
        value1: '15*10*4.5 Feet',
        d2: 'Water Capacity',
        value2: '5000 L',
        d3: 'Usage/Application',
        value3: 'All',
        d4: 'Material',
        value4: 'Fiberglass',
        d5: 'Pool Type',
        value5: 'ALL',
        d6: 'Height',
        value6: '4.5 Feet',
        d7: 'Color',
        value7: 'All',
        d8: 'Capacity',
        value8: 'Approx 16000 Ltr',
        d9: 'Filter Pump',
        value9: 'Included',
        d10: 'Design Type',
        value10: 'Rectangular',
        d11: 'Features',
        value11: 'ISO NPG Gel Coat Finish',
        d12: 'Weight',
        value12: '1000 kg',
        d13: 'Installation',
        value13: 'Included',
        d14: 'Service Location',
        value14: 'Shikrapur, Pune',
        d15: 'Country of Origin',
        value15: 'Made in India',
        para: 'Nageshwar Fibre & Fabrication Industries is renowned for manufacturing a diverse range of products, including this Portable Swimming Pool. Designed with a robust fiberglass material and featuring an ISO NPG gel coat finish, this pool ensures durability and superior quality for all your recreational needs.'
    }
    
    
  
    
    
   ]
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to change slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Automatically move the slide
  useEffect(() => {
    const sliderInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(sliderInterval); // Clean up on component unmount
  }, [currentSlide, 3000]);
  return (
    <>
      <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {index === currentSlide && (
              <img src={slide} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>


      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
             <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883|  Nageshwar Fibre And Fabrication Industries| Umbrella Manufacturers in Pune
          | Fiberglass Swimming Pool Contractors in Pune | Tube Slide Water Park Contractor in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Umbrella Manufacturers in Pune, Fiberglass Swimming Pool Contractors in Pune ,Tube Slide Water Park Contractor in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to='/contact'>Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
