import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

export default function Innovation() {
  // Styling for the slider
const styles = {
  sliderContainer: {
    position: 'relative',
    width: '100%',
    height: '400px', // You can adjust this height
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Make sure the image covers the container without distortion
    transition: 'opacity 0.5s ease-in-out',
  }
};

  const images = [
    'images/playground equipment/2-seater-frp-swan-paddle-boat-1000x1000.webp', // Replace with your image paths
    'images/playground equipment/frp multiplay station.webp',
    'images/playground equipment/frp-blue-and-yellow-family-water-slide-1000x1000.webp',
    'images/playground equipment/frp-playground-swings-500x500.webp',
    'images/Park slides/kids-multiplay-system-500x500.webp'
  ];


  // State for current image index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change images
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
     <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Plastic dustbin manufacturers in Pune, Portable Cabin Manufacturers in Pune, Fiber Door Manufacturers in Pune,FRP Planters Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section
        className="breadcrumb-header gallery-image1"
        id="page"
       
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Innovation at core</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Innovation at core</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog blog-section1">
        <div className="container">
          <div className="innovation-section">
            <h2 className="text-brown fs-1">Innovation at Nageshwar Fibre & Fabrication Industries</h2>
            <p>
              Since our inception, Nageshwar Fibre & Fabrication Industries has
              been at the forefront of innovation in the fibre and fabrication
              sector. With a strong commitment to excellence, we have
              consistently pioneered new technologies and methods that have set
              industry standards. Our focus on innovation is reflected in our
              extensive range of products and services, designed to meet the
              diverse needs of our valued clients.
            </p>

            <h3 className="text-brown fs-1">Key Dimensions of Our Innovation</h3>
            <ul>
              <li>
                <strong className="text-orange fs-4">Product Development :</strong> We continuously explore
                and implement cutting-edge materials and techniques to enhance
                the functionality, durability, and aesthetic appeal of our
                products.
              </li>
              <li>
                <strong className="text-orange fs-4">Process Improvement:</strong> Our commitment to
                innovation drives us to refine our production processes,
                ensuring efficiency and sustainability while maintaining the
                highest quality standards.
              </li>
              <li>
                <strong className="text-orange fs-4">Customer-Centric Solutions:</strong> We actively engage
                with our clients to understand their unique challenges and
                aspirations, allowing us to deliver tailored solutions that
                exceed expectations.
              </li>
            </ul>

            <p>
              Through these pillars of innovation, Nageshwar Fibre & Fabrication
              Industries remains dedicated to providing exceptional products and
              services, establishing long-lasting relationships with our
              esteemed clients.
            </p>
          </div>

          <h2 className="text-brown fs-1">Design Capabilities</h2>
          <p>
            We strongly believe that every park is unique and every slide
            designed at Nageshwar fibre and fabrication is carefully crafted to
            enhance this uniqueness. We prefer customization than
            standardization and hence all slides in a park are designed keeping
            in mind the location, topography, audience preferences, expected ROI
            by parks, positioning of each park. The slides are designed as per
            EN and ASTM standards and undergo a simulation check before they
            move to the production phase.
          </p>

          <div class="row">
            <div class="col-12 col-md-6">
              <img
                src="images/images/design innovation.jpg"
                alt=""
                className=""
              />
            </div>
            <div class="col-12 col-md-6  mybox">
              <div className="slide-simulation-benefits ">
                <h2 className="text-brown fs-2">Slide Simulation Benefits</h2>
                <ul className="benefits-list">
                  <li>
                    Can check slides path and pre-determine the safety of riders
                  </li>
                  <li>
                    Ride time can be manipulated with the length and angle of
                    slides
                  </li>
                  <li>Better throughput</li>
                  <li>Curbing any possible accidents before ride production</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services services-home-2 py-100-70">
        {/* <div className="overlay"></div> */}
        <div className="container">
          <div className="sec-title  text-white">
            <h1>Manufacturing Capabilities</h1>
            <div class="row">
              <div class="col-12 col-md-6">
                <p>
                  We have developed a modular infrastructural unit that is built
                  over a huge area. We have divided our infrastructure into
                  varied units such as production, quality testing, and
                  warehousing & packaging departments to maintain efficient work
                  flow. The production unit of our firm is installed with the
                  upgraded machinery that helps us in catering to the bulk
                  orders of our patrons. In order to retain the quality in the
                  products, we have developed a testing unit that is managed by
                  our skilled professionals. In order to attain utmost level of
                  customer satisfaction, our products are developed in
                  accordance with the specifications provided by the customers
                  with the assured quality. Under the guidance of our mentor,
                  Mr. Nikhil Wavhal (Partner), we have been able to gain the
                  trust of our customers.
                  <ul style={{"list-style-type":"disc"}}>
                    <li><strong>High-Quality Materials</strong></li>
                    <li><strong>Customization Capabilities</strong></li>
                    <li><strong>State-of-the-Art Technology</strong></li>
                    <li><strong>Expert Engineering</strong></li>
                    <li><strong>Cost-Effectiveness</strong></li>
                    <li><strong>Eco-Friendly Practices</strong></li>
                    <li><strong>Durability and Reliability</strong></li>
                    <li><strong>Timely Delivery</strong></li>
                  </ul>
                </p>
              </div>
              <div class="col-12 col-md-6  mybox">
                <div className="slide-simulation-benefits ">
                <div style={styles.sliderContainer}>
      <img 
        src={images[currentIndex]} 
        alt="Slider" 
        style={styles.image}
      />
    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section  className="service-section text-start">
        <div class="container">
             <h2 className="text-brown">Service capabilities</h2>
             <p>We,at Nageshwar Fibre & Fibrication Industries, understand that a smooth functioning park is more important than just a good looking park. Thus we carry out regular maintenance visits and slide corrections to the projects on our own accord. Our VIV program is considered as one of the best where we voluntarily audit the installed slides in order to assist you achieve better ROI on your investment!

</p>
        </div>
        
      </section>
    </>
  );
}
