import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function ProductPage() {
  const slides = [
    "slider/Slider for product section-01.jpg",
    "slider/Slider for product section-02.jpg",
  ];
  const imgData = [
    {
      img1: "Product-Images/Family-rides-at-Thorpe-park-manufactures-in-india.jpg",
      name: "Family Rides",
      price: "Rs 4 Lakh / Piece Get Best Price",
      d1: "Capacity",
      value1: "20 Person",
      d2: "Material",
      value2: "FRP",
      d3: "Usage/Application",
      value3: "Amusement Park",
      d4: "Shape",
      value4: "Oval",
      d5: "Type",
      value5: "Amusement Park Rides",
      d6: "Color",
      value6: "All Colour Available",
      para: "The Family Ride is an amusement park attraction designed with durable FRP material, accommodating up to 20 people. It features an oval shape and is customizable in any color to suit your park's aesthetic. Nageshwar Fibre & Fabrication Industries ensures top-quality manufacturing, using heavy fabrication techniques and MH pipes, making this ride both safe and enjoyable for family fun.",
    },
    {
      img1: "Product-Images/Fabricated-gate-manufactures-in-pune.jpg",
      name: "MS Fabrication Gate",
      price: "Rs 140 / Square Feet Get Best Price",
      d1: "Material",
      value1: "Aluminum",
      d2: "Open Style",
      value2: "Swing",
      d3: "Usage/Application",
      value3: "Residential",
      d4: "Surface Finish",
      value4: "Colour Coated",
      d5: "Opening Pattern",
      value5: "Manual",
      d6: "Gate Design",
      value6: "Antique",
      d7: "Country of Origin",
      value7: "Made in India",
      para: "The MS Fabrication Gate is a high-quality residential gate made from durable aluminum material. It features a classic swing style with a manual opening pattern and an antique design. The surface is color-coated for added protection and aesthetic appeal. Crafted in India, this gate is perfect for residential use, offering both functionality and elegance.",
    },
    {
      img1: "Product-Images/Fiber-toilet-manufactures-in-pune.jpg",
      name: "FRP Portable Toilet",
      price: "Rs 15,000 / Set Get Best Price",
      d1: "Material",
      value1: "FRP",
      d2: "Brand",
      value2: "Nageshwar Fibre",
      d3: "Size",
      value3: "2.5*2.5",
      d4: "No. Of Compartments",
      value4: "1",
      d5: "Service Location/City",
      value5: "All India",
      d6: "Built Type",
      value6: "Modular",
      d7: "Color",
      value7: "All",
      d8: "Shape",
      value8: "Square",
      para: "The FRP Portable Toilet by Nageshwar Fibre is a compact and durable solution for sanitation needs. Designed using premium FRP material, it measures 2.5*2.5 feet and comes with one compartment. Ideal for modular setups, this toilet is available in all colors and is suitable for use across all of India. Its square shape and robust build make it a reliable choice for portable and temporary installations.",
    },
    {
      img1: "Product-Images/FRP-Door-manufactures-in-pune.jpg",
      name: "Fibre Bathroom Door",
      price: "Rs 2,800 / Piece Get Best Price",
      d1: "Material",
      value1: "Fibre",
      d2: "Finish Type",
      value2: "Wooden Finish and Molding Finish",
      d3: "Color",
      value3: "All Colours",
      d4: "Design/Pattern",
      value4: "Design",
      d5: "Type",
      value5: "Fibre Door",
      d6: "Country of Origin",
      value6: "Made in India",
      para: "The Fibre Bathroom Door by Nageshwar Fibre is a stylish and durable option for modern homes. Available in a wide range of colors and designs, this door features a wooden and molding finish that enhances its aesthetic appeal. Made from high-quality fibre, it is designed for long-lasting performance. Ideal for bathrooms, this door offers a perfect blend of functionality and elegance, ensuring both privacy and durability.",
    },
    {
      img1: "Product-Images/School-bench-manufactures-in-pimpari-chinhwad.jpg",
      name: "Fiber School Benches",
      price: "Rs 3,800 / Piece Get Best Price",
      d1: "Material",
      value1: "Wooden",
      d2: "Usage/Application",
      value2: "School Bench",
      d3: "Color",
      value3: "All Colors",
      d4: "Seating Capacity",
      value4: "2",
      d5: "Size",
      value5: "32 inches top",
      d6: "Country of Origin",
      value6: "Made in India",
      para: "The Fiber School Benches are designed for durability and comfort, perfect for school use. Made with wooden material and a 32-inch top, these benches can seat two students comfortably. Available in all colors, they are crafted using FRP and fabrication materials for added strength. Manufactured in India, these benches combine practicality and quality, making them a reliable choice for educational institutions.",
    },
    {
      img1: "Product-Images/Garbage-Track-manufactures-in-pune.jpg",
      name: "Garbage Collection Trucks",
      price: "Rs 95 / Piece Get Best Price",
      d1: "Load Body Options",
      value1: "Mild Steel",
      d2: "Truck Type",
      value2: "Distribution",
      d3: "Brand",
      value3: "TATA",
      d4: "Fuel Type",
      value4: "Petrol",
      d5: "Surface Finish",
      value5: "Color Coated",
      d6: "Country of Origin",
      value6: "Made in India",
      para: "The Garbage Collection Trucks by Nageshwar Fibre & Fabrication Industries are built with mild steel load body options and are ideal for distribution purposes. Powered by petrol and featuring a durable color-coated surface finish, these trucks are manufactured in India. Offering high-quality fabrication at a low budget, these 'Ghantagadi' trucks are perfect for efficient waste collection and management.",
    },
    {
      img1: "Product-Images/Designer-wall-mural-manufactures-in-pune.jpg",
      name: "FRP Decorative Mural",
      price: "Rs 1,100 / sq ft Get Best Price",
      d1: "Usage/Application",
      value1: "Home Decor",
      d2: "Material",
      value2: "Fiber",
      d3: "Finish",
      value3: "FRP Mural",
      d4: "Size",
      value4: "All Size",
      d5: "Theme",
      value5: "Wall Decoration",
      d6: "Type Of Frame",
      value6: "Fiber",
      d7: "Color",
      value7: "Brown",
      para: "The FRP Decorative Mural is an elegant and versatile choice for home decor. Made from durable fiber material, this mural offers a sophisticated FRP finish and is available in all sizes. With a wall decoration theme and fiber frame, the mural adds a unique touch to any room. Available in brown, this mural is crafted with precision and designed for lasting beauty, making it a perfect addition to your home.",
    },
    
    
    {
      img1: "Product-Images/FRP Temple Chariot.jpg",
      name: "FRP Temple Chariot / Silver Rath",
      price: "Rs 7.5 Lakh / Set Get Best Price",
      d1: "Material",
      value1: "Fiber",
      d2: "Finishing",
      value2: "Glossy",
      d3: "Country of Origin",
      value3: "Made in India",
      para: "The FRP Temple Chariot, also known as the Silver Rath, is a magnificent structure made from high-quality fiber material with a glossy finish. Manufactured in India by Nageshwar Fibre & Fabrication Industries, this chariot is crafted with exceptional precision and artistry. Designed for temple processions and religious events, it symbolizes tradition and grandeur, offering a stunning visual appeal and long-lasting durability.",
    },
    {
      img1: "Product-Images/Sports-Trampoline-manufactures-in-pune.jpg",
      name: "Bungee Jumping Trampoline",
      price: "Rs 35,000 / Piece Get Best Price",
      d1: "Material",
      value1: "Steel",
      d2: "Shape",
      value2: "Round and Hexagon",
      d3: "Customisation",
      value3: "Customized",
      d4: "Age Group",
      value4: "Upto 10 Years",
      d5: "Capacity",
      value5: "Max-150kg",
      d6: "Number Of Poles",
      value6: "6",
      d7: "Available Sizes",
      value7: "7 Feet",
      para: "The Bungee Jumping Trampoline is an exciting attraction designed for children up to 10 years of age, made from high-quality steel. Available in round and hexagon shapes with a diameter of 7 feet, this trampoline features a maximum weight capacity of 150kg and is supported by six sturdy poles. Perfect for amusement parks and recreational areas, it offers a safe and fun jumping experience, with customization options available to suit your needs.",
    },
    {
      img1: "Product-Images/Seasaw-play-equipment-manufactures-in-pune.jpg",
      name: "FRP 4 Seater See Saw",
      price: "Rs 15,000 / Piece Get Best Price",
      d1: "Color",
      value1: "All Colours",
      d2: "Type",
      value2: "2 Seater",
      d3: "Material",
      value3: "Fibre",
      d4: "Location Type",
      value4: "Outdoor",
      d5: "Customized",
      value5: "No",
      para: "The FRP 4 Seater See Saw is a fun and engaging playground equipment designed for outdoor use. Made from high-quality fiber material, this see saw is available in a variety of colors and is perfect for parks and recreational areas. With a sturdy design that can accommodate multiple users, it provides a delightful play experience while ensuring durability and safety.",
    },
    {
      img1: "Product-Images/Vehicle Body Fabrication Works.jpg",
      name: "Vehicle Body Fabrication Works",
      price: "Rs 25,000 / Piece Get Best Price",
      d1: "Service Location",
      value1: "Shikrapur, Pune",
      d2: "Product Type",
      value2: "MS Material",
      d3: "Payment Mode",
      value3: "Offline",
      d4: "Vehicle",
      value4: "TATA",
      d5: "Shape",
      value5: "Rectangular",
      d6: "Material Procurement",
      value6: "Our End",
      para: "Nageshwar Fibre & Fabrication Industries offers professional Vehicle Body Fabrication Works, utilizing high-quality MS materials. Based in Shikrapur, Pune, our services include designing and manufacturing vehicle bodies for TATA vehicles in a rectangular shape. Payment is accepted offline, and our skilled team ensures superior quality by sourcing materials from reliable vendors.",
    },
    {
      img1: "Product-Images/Food-van-manufactures-in-pune.jpg",
      name: "Food Truck Food Van",
      price: "Rs 25,000 / Piece Get Best Price",
      d1: "Usage/Application",
      value1: "Food Transport Van",
      d2: "Fuel Type",
      value2: "Diesel",
      d3: "Power",
      value3: "Diesel",
      d4: "Type",
      value4: "Non AC",
      d5: "Vehicle Brand",
      value5: "TATA",
      d6: "Vehicle Model",
      value6: "TATA Ace",
      d7: "Brand",
      value7: "NFF Industries",
      d8: "No. Of Wheels",
      value8: "3",
      d9: "No. Of Compartments",
      value9: "2 Above",
      d10: "Displacement",
      value10: "200 cc",
      d11: "Fuel Tank Capacity",
      value11: "50 L",
      d12: "Seating Capacity",
      value12: "Double Seater",
      para: "The Food Truck Food Van from Nageshwar Fibre & Fabrication Industries is an ideal solution for food transport. Designed for TATA Ace vehicles, this non-AC van operates on diesel, featuring a powerful 200 cc engine and a fuel tank capacity of 50 liters. With three wheels and more than two compartments, it provides ample space for food storage and transport, comfortably seating two people. Proudly made in India, this van is crafted with superior materials and expert manufacturing.",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to change slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Automatically move the slide
  useEffect(() => {
    const sliderInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(sliderInterval); // Clean up on component unmount
  }, [currentSlide, 3000]);
  return (
    <>
     <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries |Outdoor Swing Manufacturers in Pune | Wedding Doli Manufacturer in Pune | Shivaji Maharaj Statue Maker in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Outdoor Swing Manufacturers in Pune, Wedding Doli Manufacturer in Pune, Shivaji Maharaj Statue Maker in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {index === currentSlide && (
              <img src={slide} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>

      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact"></Link>Buy Now</button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
