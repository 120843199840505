import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function JeepRathManufacturesInPune(){
    const imgData = [
        {
            "img1": "Product-Images/FRP Jeep Rath.jpg",
            "name": "FRP Jeep Rath",
            "price": "Rs 2.55 Lakh / Piece",
            "d1": "Material",
            "value1": "Fiber (FRP)",
            "d2": "Color",
            "value2": "White",
            "d3": "Finishing",
            "value3": "Glossy",
            "d4": "Type",
            "value4": "Jeep Rath",
            "d5": "Usage",
            "value5": "Decoration",
            "d6": "Country of Origin",
            "value6": "Made in India",
            "para": "Nageshwar Fibre & Fabrication Industries offers a stunning FRP Jeep Rath, perfect for decorative purposes. Made from high-quality fiber with a glossy finish, this Jeep Rath combines durability and elegance. Our products are designed and manufactured by experienced professionals using superior raw materials and advanced techniques to ensure top-notch quality."
        },
        {
            "img1": "Product-Images/FRP Temple Chariot.jpg",
            "name": "FRP Temple Chariot (Silver Rath)",
            "price": "Rs 7.5 Lakh / Set",
            "d1": "Material",
            "value1": "Fiber",
            "d2": "Color",
            "value2": "White",
            "d3": "No. Of Wheels",
            "value3": "4",
            "d4": "Surface Type",
            "value4": "Painted",
            "d5": "Type",
            "value5": "Temple Chariot",
            "d6": "Finishing",
            "value6": "Glossy",
            "d7": "Minimum Order Quantity",
            "value7": "1 Set",
            "para": "Nageshwar Fibre & Fabrication Industries presents a magnificent FRP Temple Chariot, also known as Silver Rath, ideal for religious processions and decorations. Crafted from high-quality fiber with a glossy finish and four durable wheels, this chariot combines elegance and functionality. Designed and manufactured by skilled professionals, it ensures superior quality and durability for all ceremonial needs."
        },
        {
            "img1": "Product-Images/Wedding Jeep Rath 1.jpg",
            "name": "Wedding Jeep Rath",
            "price": "Rs 1.8 Lakh / Event",
            "d1": "Shape",
            "value1": "Chariot",
            "d2": "Size/Dimension",
            "value2": "Width - 6 feet, Height - 12 feet",
            "d3": "Color",
            "value3": "Any",
            "d4": "Weight",
            "value4": "1.5 Tonne",
            "d5": "Purpose",
            "value5": "Events",
            "d6": "Event Location",
            "value6": "Pan India",
            "d7": "Minimum Order Quantity",
            "value7": "3 Events",
            "para": "Nageshwar Fibre & Fabrication Industries offers an elegant Wedding Jeep Rath, perfect for grand events and ceremonies. With a stunning chariot shape, this jeep measures 6 feet in width and 12 feet in height, weighing 1.5 tonnes. Available in any color, it is designed for events across India, ensuring high quality and superior craftsmanship for unforgettable celebrations."
        },                
        
    ]
    return(
        <>
        <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Plastic dustbin manufacturers in Pune, Portable Cabin Manufacturers in Pune, Fiber Door Manufacturers in Pune,FRP Planters Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
          <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>frp Jeep Rath </h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>frp Jeep Rath Manufacturers in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      
                {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
          frp Jeep Rath Manufacturers in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
         <p>Nageshwar Fibre and Fabrication Industries is a leading manufacturer of high-quality FRP (Fiber Reinforced Plastic) Jeep Raths in Pune. Our products are crafted with precision and care, ensuring they meet the highest standards of durability and aesthetic appeal.</p>
        </div>
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


        {/*  ================= Contact Details ==================== */}
        <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        </>
    )
}