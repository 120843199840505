import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";

export default function WeddingHorseRathManufacturesInPune(){
    const imgData = [
        {
          img1: "Product-Images/Wedding-horse-rath-manufactures-from-pune.jpg",
          name: "Wedding Horse Rath",
          price: "Rs 3.5 Lakh / Event",
          d1: "Material",
          value1: "FRP (Fiber Reinforced Plastic) and MS Material",
          d2: "Service Location",
          value2: "All India, 1 Month",
          d3: "Event Location",
          value3: "All City",
          d4: "Design",
          value4: "Customer Demand",
          d5: "Product Type",
          value5: "FRP",
          d6: "Corrosion Resistance",
          value6: "Yes",
          d7: "Usage/Application",
          value7: "Fiber Rath",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
        },
        {
          img1: "Product-Images/Wedding Jeep Rath 1.jpg",
          name: "Wedding Jeep Rath",
          price: "Rs 1.8 Lakh / Event",
          d1: "Minimum Order Quantity",
          value1: "3 Events",
          d2: "Event Location",
          value2: "Pan India",
          d3: "Shape",
          value3: "Chariot",
          d4: "Size/Dimension",
          value4: "Width - 6 feet, Height - 12 feet",
          d5: "Color",
          value5: "Any",
          d6: "Weight",
          value6: "1.5 Tonne",
          d7: "Purpose",
          value7: "Events",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
        },
        {
          img1: "Product-Images/Wedding horse baggi.jpg",
          name: "Wedding Horse Baggi",
          price: "Rs 3.5 Lakh / Event",
          d1: "Usage/Application",
          value1: "Events",
          d2: "Event Location",
          value2: "Pune",
          d3: "Pattern",
          value3: "Traditional",
          d4: "Surface Finish",
          value4: "Color Coated",
          d5: "Type",
          value5: "Wedding Horse Baggi",
          d6: "Special Feature",
          value6: "Golden Baggi in Panel",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
        },
       
        {
          img1: "Product-Images/Wedding-horse-rath-manufactures-from-pune.jpg",
          name: "Wedding Baggi Service",
          price: "Rs 2.2 Lakh / Event",
          d1: "Minimum Order Quantity",
          value1: "2 Events",
          d2: "Service Location",
          value2: "Pune",
          d3: "Usage/Application",
          value3: "Events",
          d4: "Event Location",
          value4: "Pune",
          d5: "Type of Service",
          value5: "Own Vehicle (we don't provide the vehicle)",
          d6: "Purpose",
          value6: "Wedding",
          d7: "Material & Features",
          value7: "Heavy structure and Fibre material with Lighting",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
        },
      
      ];
    return(
        <>
          <Helmet>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191,
          +91 9762431883| Nageshwar Fibre And Fabrication Industries| Fiber Bathroom Door Manufacturers in Pune
          | Fibre School Furniture Manufacturers in Pune | Garbage Truck Manufacturers in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Bathroom Door Manufacturers in Pune
          | Fibre School Furniture Manufacturers in Pune | Garbage Truck Manufacturers in Pune
        "
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

        <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Wedding Baggi</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Wedding Horse Rath Manufacturer from Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
           {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}
        <div className="container product-section">
          <h1>
           Wedding Horse Rath Manufacturer from Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
           <p>Nageshwar Fibre And Fabrication Industries is a leading manufacturer of wedding horse raths in Pune, known for creating beautifully designed and durable raths that enhance the grandeur of Indian wedding processions. Our raths combine traditional craftsmanship with modern manufacturing techniques, offering a perfect blend of elegance and comfort for royal wedding celebrations.</p>
        </div>

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to='/contact'>Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>

       {/*  ================= Contact Details ==================== */}
       <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        </>
    )
}